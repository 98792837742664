import { core } from '@icc/common/helpers';
import { ActiveMullion } from '@icc/common/layout/active-mullion';
import { ActiveSash } from '@icc/common/layout/active-sash';
import {
    Layout,
    LayoutSash,
    FrameProfile,
    LayoutFromCode,
    LayoutDiv,
    LayoutSashType,
    getDefaultShapeDimensions,
} from '@icc/window';
import {
    WindowActiveConfiguration,
    TranslateService,
    APP_CONFIG,
    AppConfigFactory,
    ConfiguratorsDataService,
    ConfigurationsService,
    ParametersService,
    ProfilesService,
    EventBusService,
    ValidationService,
    SizeRangeService,
} from '@icc/common';
import { IccAccessoryColor, IccSashType } from '@icc/common/data-types';
import { IWindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import {
    isDefined,
    isUndefined,
    InfoService,
    IssuesService,
    StepsService,
    IssueLevel,
    isObject,
    isArray,
    ChangedStepValue,
} from '@icc/helpers';
import { Injectable, Inject } from '@angular/core';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { SashesLayoutService } from './sashes-layout.service';
import { PriceService } from '@icc/price';
import { DimensionsService } from '../steps/window/dimensions/dimensions.service';
import { BrowserShapeService } from './shape.service';
import { WarrantyService } from '@icc/legacy/price/warranty.service';
import { ConstructionLimitationService } from '../steps/window/dimensions/construction-limitation.service';
import { BrowserFramesService } from './frames.service';
import { SashTypesService } from './sash-types.service';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { LocksService } from '../steps/door/locks/locks.service';
import { MuntinsService } from '../steps/window/muntins/muntins.service';
import { HandlesService } from '../steps/window/handles/handles.service';
import { ConstructionCodeService } from '@icc/common/layout/construction-code.service';
import { SizesService } from 'libs/configurator/door/src/lib/sizes/sizes.service';
import { ThresholdsService } from './thresholds.service';
import { SashesTypesService } from '../steps/window/sashes/sashes-types.service';
import { AccessoriesService } from '../steps/window/accessories/accessories.service';
import { EqualDivisionService } from './equal-division.service';

@Injectable()
export class LayoutService {
    passiveSashTypes = ['F', 'FF', 'OFF', 'DS', 'ODS', 'DSC', 'DRP', 'DOP'];
    dividers = [];
    sashTypes = [];
    defaultLayouts = [];
    defaultVariants = [];
    orderVariants = [];
    autoSelectLayout = false;
    private dependencies: {
        blockedSashLayoutVariant?: boolean; id: number; conditions: { type?: string, value?: any; }[];
    }[] = [];
    constructor(
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private configuratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private currentConfiguratorService: CurrentConfiguratorService,
        private sashesLayoutService: SashesLayoutService,
        private infoService: InfoService,
        private issuesService: IssuesService,
        private priceService: PriceService,
        private dimensionsService: DimensionsService,
        private stepsService: StepsService,
        private parametersService: ParametersService,
        private shapeService: BrowserShapeService,
        private warrantyService: WarrantyService,
        private constructionLimitationService: ConstructionLimitationService,
        private profilesService: ProfilesService,
        private eventBusService: EventBusService,
        private validationService: ValidationService,
        private framesService: BrowserFramesService,
        private sashTypesService: SashTypesService,
        private locksService: LocksService,
        private handlesService: HandlesService,
        private muntinsService: MuntinsService,
        private constructionCodeService: ConstructionCodeService,
        private sizesService: SizesService,
        private sizeRangeService: SizeRangeService,
        private sashesTypesService: SashesTypesService,
        private accessoriesService: AccessoriesService,
        private equalDivisionService: EqualDivisionService,
    ) {
        if (this.configuratorsDataService.loaded && this.configurationsService.conf) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
            this.init();
        });
        this.eventBusService.subscribeWithoutConfiguration(
            ['setSystem', 'changedDoorSizes'],
            () => {
                this.initDefaultLayout();
                this.validAndFixDoorLayout();
            }
        );

        this.eventBusService.subscribe<ChangedStepValue>('changedStep', data => {
            this.validateLayoutForSizeRanges();
            if (
                data.value.nextStep.code !== 'sashes'
                && this.stepsService.getStepByCode('sashes') > -1
                && data.value.nextStep.i !== this.stepsService.getStepByCode('sashes')
                && data.value.nextStep.code !== data.value.prevStep.code
            ) {
                if (
                    !(
                        data.value.nextStep.i <= this.stepsService.getStepByCode('sashes')
                        || this.validateLayout()
                    )
                ) {
                    this.stepsService.selectStep(data.value.prevStep.i);
                }
            }
        });
    }
    public hideFittingStepIfFixedLayout(){
        if (this.configurationsService.conf.Current.SashesType==='FixSash' || this.configurationsService.conf.Current.SashesType==='Fix'){
            this.stepsService.disable('fitting');
        } else {
            this.stepsService.enable('fitting');
        }
    }
    init() {
        this.issuesService.addValidateFunction(this.validateLayout.bind(this));

        this.sashTypes = this.configuratorsDataService.data.sashTypes;
        this.dividers = this.configuratorsDataService.data.dividers || [];
        this.defaultLayouts = this.configuratorsDataService.layouts || [];
        this.defaultVariants = this.configuratorsDataService.layoutsVariants || [];
        this.dependencies = this.configuratorsDataService.data.dependencies || [];
        if (this.configurationsService.conf.Current) {
            this.hideFittingStepIfFixedLayout();
        }
    }

    private initDefaultLayout() {
        const conf = this.configurationsService.conf.Current;
        const adminThumb = ~~sessionStorage.getItem('adminThumb');
        const hasSystem = conf && conf.System && conf.System.id;
        const canBeSelected =
            conf
            && ((conf.type === 'window'
                && this.config().IccConfig.Configurators.window.autoSelectLayout)
                || (conf.type === 'door'
                    && this.config().IccConfig.Configurators.door.autoSelectLayout)
                || (adminThumb
                    && ['window', 'hs', 'door', 'folding_door', 'sliding_door'].includes(
                        conf.type
                    )));
        const hasNotSashes = conf && conf.Sashes.length === 0;
        const selectLayout =
            (conf.type === 'window'
            && this.config().IccConfig.Configurators.window.autoSelectLayout)
            || (hasSystem
                && canBeSelected
                && hasNotSashes
                && ((conf.type === 'door'
                    && (conf.System?.door_type && conf.doorSizes && conf.doorSizes.sashSizeId))
                    || !conf.System?.door_type
                    || conf.type !== 'door'));
        if (selectLayout) {
            this.selectDefaultLayout();
        }
    }

    public selectLayout(layout: Layout, auto = false) {
        const preparedLayout = this.prepareSashesLayout(layout);
        const conf = this.configurationsService.conf.Current;
        return new Promise((resolve, reject) => {
            if (auto || !conf.Layout || conf.Sashes.length === 0) {
                this.selectWindowSashesLayout(preparedLayout);
                this.hideFittingStepIfFixedLayout();
                resolve();
            } else {
                const currentCode = this.constructionCodeService
                    .getConstructionCode({
                        width: conf.Width,
                        height: conf.Height,
                        sashes: conf.Sashes,
                        dividers: conf.Mullions,
                        frames: conf.Frames,
                        couplings: conf.couplings,
                        type: conf.System.type,
                        conf,
                    })
                    .code.replace(/\[[A-Z]+\]/g, '[▣]');
                const canBeReplaced = currentCode === preparedLayout.layoutBaseCode;

                if (this.config().IccConfig.Configurators.confirmationModalOnLayoutChange === false) {
                    this.selectWindowSashesLayout(preparedLayout);
                    this.hideFittingStepIfFixedLayout();
                    resolve();
                } else {
                    this.infoService.openConfirmModal(
                        this.translateService.instant('WINDOW|Zmiana układu konstrukcji'),
                        canBeReplaced
                            ? this.translateService.instant(
                                  'WINDOW|Zmiana układu może zresetować ustawienia wypełnień, klamek i szprosów w skrzydłach. Czy zresetować te ustawienia?'
                              )
                            : this.translateService.instant(
                                  'WINDOW|Zmiana układu zresetuje ustawienia wypełnień, klamek i szprosów w skrzydłach.'
                              ),
                        [
                            {
                                name: canBeReplaced
                                    ? this.translateService.instant('INTERFACE|Tak')
                                    : this.translateService.instant('INTERFACE|Ok'),
                                callback: () => {
                                    this.selectWindowSashesLayout(preparedLayout);
                                    this.hideFittingStepIfFixedLayout();
                                    resolve();
                                },
                            },
                            ...(canBeReplaced
                                ? [
                                      {
                                          name: this.translateService.instant('INTERFACE|Nie'),
                                          callback: () => {
                                              this.replaceSashTypes(
                                                  preparedLayout,
                                                  this.sashTypes,
                                                  conf
                                              );
                                              this.hideFittingStepIfFixedLayout();
                                              resolve();
                                          },
                                      },
                                  ]
                                : []),
                            {
                                name: this.translateService.instant('INTERFACE|Anuluj'),
                                callback: () => {
                                    reject();
                                },
                                accent: true,
                            },
                        ]
                    );
                }
            }
        });
    }
    public validateLayoutForSizeRanges() {
        const conf = this.configurationsService.conf.Current;
        const layout = this.configurationsService.conf.Current.Layout;
        const variant = this.getLayoutById(layout?.id);

        if (variant?.SashesLayoutsVariant?.size_range_id) {
            const sizeRanges = this.sizeRangeService.getSizeRange(
                variant.SashesLayoutsVariant.size_range_id,
                conf?.Width,
                conf?.Height
            );
            if (sizeRanges && !sizeRanges?.isValid) {
                this.selectDefaultLayout();
            }
        }
    }
    /**
     * Wybiera wariant układ który pasuje do danego rozmiaru.
     * Warianty mają maksymalne wymiay do których pasują.
     * Jeżeli nie znajdzie pasującego wariantu wybiera pierwszy dostępny
     */
    public selectDefaultLayout(doorSideWZ?, doorSideLR?, oldLayout?) {
        const adminThumb = ~~sessionStorage.getItem('adminThumb');
        if (
            adminThumb
            && ['window', 'hs', 'door', 'folding_door', 'sliding_door'].includes(
                this.configurationsService.conf.conf
            )
        ) {
            const defaultLayout = this.defaultLayouts.find(
                layout => Number(layout.SashesLayoutsVariant.id) === adminThumb
            );
            if (defaultLayout) {
                this.selectLayout(defaultLayout, true);
                (window as any).sashesLayoutId = defaultLayout.SashesLayout.id;
                return;
            }
        }

        // wpisana wysokość i szerokość
        let width = this.configurationsService.conf.Current.Width;
        let height = this.configurationsService.conf.Current.Height;

        // konfiguracja
        const conf = this.config().IccConfig.Configurators.window.variantsChoice;

        // odejmij start (poniżej start wymiary powinny być złe) i podziel przez krok, wyjdzie indeks
        width = Math.floor((width - conf.start_x) / conf.krok_x);
        height = Math.floor((height - conf.start_y) / conf.krok_y);

        // domyślny wariant
        let filteredLayouts = this.getLayouts(
            this.configurationsService.conf.Current
        )

        if (this.config().IccConfig.Configurators.window?.filterLayoutsOnSizeRanges){
            const windowPrices = Object.values(this.configuratorsDataService.data.windowPrices)
                .filter((windowPrice: any) => windowPrice.data);

                filteredLayouts = filteredLayouts.filter(el => {
                    const prices = windowPrices.filter((p: any) =>
                        p.sashes_layouts.flat()
                            .some((s: any) =>
                                el.SashesLayoutsVariant.code.includes(s)
                            )
                        );
                    const isInRange = this.getLayoutRestrictionMessage(prices, this.configurationsService.conf.Current.System.id,this.configurationsService.conf.Current.Width,this.configurationsService.conf.Current.Height);  //
                return !isInRange.show;
            });
        }

        let defaultLayout = filteredLayouts.length ? filteredLayouts[0] : this.defaultLayouts[0];
        const isAlreadyPickedLayoutAcessible = filteredLayouts.filter(layout => Number(layout.SashesLayoutsVariant?.id) === Number(this.configurationsService.conf.Current.Layout?.id))?.length > 0;
        if (this.configurationsService.conf.Current.type === 'door') {
            defaultLayout = this.matchDefaultLayoutToSelectedOne(doorSideWZ, doorSideLR, oldLayout);
        }

        if (!isAlreadyPickedLayoutAcessible || this.configurationsService.conf.Current.type === 'door'){
            this.selectLayout(
                defaultLayout,
                true
            );
        }
    }

    hasSashType(branch, sashType, sashes) {
        if (
            branch
            && branch.sashesIds
            && branch.sashesIds.some(el => sashes[el] && sashes[el].type === sashType)
        ) {
            return true;
        } else {
            return (
                branch.branches
                && branch.branches.some(el => el && this.hasSashType(el, sashType, sashes))
            );
        }
    }

    /**
     * Funkcja przygotowania szablonu skrzydeł
     * @param  {Object} sash Skrzydło
     * @return {Object}      Szablon
     */
    public prepareSashesLayout(sash): Layout {
        const adminThumb = ~~sessionStorage.getItem('adminThumb');
        if (
            !this.configurationsService.conf
            || !this.configurationsService.conf.Current
            || !sash
            || !sash.SashesLayout
            || !sash.SashesLayoutsVariant
        ) {
            return;
        }

        let transomsOnSeperateFrames = false;

        if (
            sash.Neighbours
            && sash.Neighbours.middle.some(s => s.type === 'DOA')
            && (sash.Neighbours.middle.some(s => s.type === 'F')
                || sash.Neighbours.top.some(s => s.type === 'F')
                || sash.Neighbours.bottom.some(s => s.type === 'F'))
            && DoorActiveConfiguration.is(this.configurationsService.conf.Current)
            && !this.configurationsService.conf.Current.System.door_type
            && sash.SashesLayoutsVariant.side
        ) {
            transomsOnSeperateFrames = true;
            this.infoService.openWarning(
                this.translateService.instant(
                    'WINDOW|Naświetla na osobnych ramach. Wymagane łączniki, do dokupienia w kroku dodatki.'
                )
            );
        }

        if (this.configurationsService.conf.Current.Shape.shape !== sash.SashesLayout.shape_id) {
            this.dimensionsService.setShape(
                sash.SashesLayout.shape_id,
                this.configurationsService.conf.Current
            );
            this.dimensionsService.setShapeData();
        }

        const layout: Layout = {
            id: sash.SashesLayoutsVariant.id,
            conf: sash.SashesLayout.type,
            countSashes: sash.SashesLayout.all_sashes_count,
            name: sash.SashesLayoutsVariant.name,
            shape: sash.SashesLayout.shape_id,
            isMirrorLayoutAvailable: sash.SashesLayoutsVariant.allow_mirror_layout,
            sashes: [],
            divs: [],
            changed: false,
            fromCode: false,
            layoutCode: null,
            layoutBaseCode: null,
            sashTypes: sash.Sashes,
            transomsOnSeperateFrames,
            equalDivision: sash.SashesLayoutsVariant.equal_division,
        };
        let layoutBaseCode = '';

        if (sash.SashesLayout.layout === 'code' && sash.SashesLayout.layout_code) {
            const sashes: LayoutSash[] = [];
            const divs: LayoutDiv[] = [];
            let index = { i: 0 };
            const error = { value: 0 };
            const schema = core.copy(sash.SashesLayout.layout_code);
            layout.layoutCode = core.copy(sash.SashesLayout.layout_code);
            this.indexSashes(schema, index);
            index = { i: 0 };
            this.prepareDividersFromCode(schema, divs, index);
            divs.forEach(div => {
                const topLeftSashes = div.sashes[div.direction === 'horizontal' ? 'top' : 'left'];
                const bottomRightSashes =
                    div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'];
                if (topLeftSashes.length >= 2) {
                    for (let i = 0; i < topLeftSashes.length - 1; i++) {
                        const perpendicularDiv = divs.find(
                            el =>
                                el.sashes[div.direction === 'horizontal' ? 'left' : 'top'].indexOf(
                                    topLeftSashes[i]
                                ) > -1
                                && el.sashes[
                                    div.direction === 'horizontal' ? 'right' : 'bottom'
                                ].indexOf(topLeftSashes[i + 1]) > -1
                        );
                        if (perpendicularDiv) {
                            div.divs[div.direction === 'horizontal' ? 'top' : 'left'].push(
                                perpendicularDiv.id
                            );
                        }
                    }
                }
                if (bottomRightSashes.length >= 2) {
                    for (let i = 0; i < bottomRightSashes.length - 1; i++) {
                        const perpendicularDiv = divs.find(
                            el =>
                                el.sashes[div.direction === 'horizontal' ? 'left' : 'top'].indexOf(
                                    bottomRightSashes[i]
                                ) > -1
                                && el.sashes[
                                    div.direction === 'horizontal' ? 'right' : 'bottom'
                                ].indexOf(bottomRightSashes[i + 1]) > -1
                        );
                        if (perpendicularDiv) {
                            div.divs[div.direction === 'horizontal' ? 'bottom' : 'right'].push(
                                perpendicularDiv.id
                            );
                        }
                    }
                }
            });
            if ((sash.SashesLayout.recommended_width || sash.SashesLayout.recommended_height) && adminThumb > 0){
                if (sash.SashesLayout.recommended_width && Number(sash.SashesLayout.recommended_width) > 0 ){
                    this.configurationsService.conf.Current.Width = Number(sash.SashesLayout.recommended_width);
                }
                if (sash.SashesLayout.recommended_height && Number(sash.SashesLayout.recommended_height) > 0 ){
                    this.configurationsService.conf.Current.Height = Number(sash.SashesLayout.recommended_height);
                }
                this.configurationsService.conf.Current.Shape = getDefaultShapeDimensions('rect', this.configurationsService.conf.Current.Width, this.configurationsService.conf.Current.Height);
            }
            this.prepareLayoutFromCode(
                schema,
                this.configurationsService.conf.Current.Width,
                this.configurationsService.conf.Current.Height,
                this.configurationsService.conf.Current.Width,
                this.configurationsService.conf.Current.Height,
                divs,
                sash.Sashes,
                sashes,
                error
            );

            if (error.value) {
                this.infoService.openWarning(
                    this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.')
                );
            } else {
                if (
                    DoorActiveConfiguration.is(this.configurationsService.conf.Current)
                    && !this.configurationsService.conf.Current.System.door_type
                    && sash.SashesLayoutsVariant.side
                    && schema.division === '_'
                ) {
                    if (this.hasSashType(schema, 'DOA', sashes)) {
                        if (this.hasSashType(schema, 'F', sashes)) {
                            layout.transomsOnSeperateFrames = true;
                            this.infoService.openWarning(
                                this.translateService.instant(
                                    'WINDOW|Naświetla na osobnych ramach. Wymagane łączniki, do dokupienia w kroku dodatki.'
                                )
                            );
                        }
                    }
                }

                layout.sashes = sashes;
                layout.divs = divs;
                layout.countSashes = sashes.length;
                layout.fromCode = true;
                layout.layoutBaseCode = schema.layoutBaseCode;
            }
        } else {
            let k, s;
            let defaultSashHeightRatio = 0.2;

            if (sash.Neighbours.top.length > 0) {
                layoutBaseCode += '(';
            }

            for (k = 0; k < sash.Neighbours.top.length; k++) {
                s = sash.Neighbours.top[k];
                if (s.type === 'K') {
                    defaultSashHeightRatio =
                        Math.round((500 / this.configurationsService.conf.Current.Height) * 100)
                        / 100;
                }

                layout.sashes.push({
                    id: Number(s.position),
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height: defaultSashHeightRatio,
                    width: 1 / sash.Neighbours.top.length,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors: {
                        top: [],
                        left:
                            k === 0 || sash.Neighbours.top.length === 1
                                ? []
                                : [Number(s.position) - 1],
                        right:
                            k + 1 === sash.Neighbours.top.length || sash.Neighbours.top.length === 1
                                ? []
                                : [Number(s.position) + 1],
                        bottom: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                    },
                    divs: {
                        top: -1,
                        left:
                            k === 0 || sash.Neighbours.top.length === 1
                                ? -1
                                : Number(s.position) - 1,
                        right:
                            k + 1 === sash.Neighbours.top.length || sash.Neighbours.top.length === 1
                                ? -1
                                : Number(s.position),
                        bottom: sash.Neighbours.top.length - 1,
                    },
                });

                if (k > 0) {
                    layout.divs[Number(s.position) - 1] = {
                        id: Number(s.position) - 1,
                        direction: 'vertical',
                        position: (1 / sash.Neighbours.top.length) * k,
                        length: 1,
                        sashes: {
                            top: [],
                            bottom: [],
                            left: [Number(s.position) - 1],
                            right: [Number(s.position)],
                        },
                        divs: {
                            top: [],
                            bottom: [],
                            left: [],
                            right: [],
                        },
                    };
                    layoutBaseCode += '|';
                }

                layoutBaseCode += '[▣]';
            }
            if (sash.Neighbours.top.length > 0 && sash.Neighbours.middle.length > 0) {
                layoutBaseCode += ')_(';
            }

            for (k = 0; k < sash.Neighbours.middle.length; k++) {
                s = sash.Neighbours.middle[k];
                layout.sashes.push({
                    id: Number(s.position),
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height:
                        1
                        - (sash.Neighbours.top.length > 0 ? defaultSashHeightRatio : 0)
                        - (sash.Neighbours.bottom.length > 0 ? 0.2 : 0),
                    width: 1 / sash.Neighbours.middle.length,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors: {
                        top: sash.Neighbours.top.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                        left:
                            k === 0 || sash.Neighbours.middle.length === 1
                                ? []
                                : [Number(s.position) - 1],
                        right:
                            k + 1 === sash.Neighbours.middle.length
                            || sash.Neighbours.middle.length === 1
                                ? []
                                : [Number(s.position) + 1],
                        bottom: sash.Neighbours.bottom.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                    },
                    divs: {
                        top: sash.Neighbours.top.length === 0 ? -1 : sash.Neighbours.top.length - 1,
                        left:
                            k === 0 || sash.Neighbours.middle.length === 1
                                ? -1
                                : Number(s.position) - 1,
                        right:
                            k + 1 === sash.Neighbours.middle.length
                            || sash.Neighbours.middle.length === 1
                                ? -1
                                : Number(s.position),
                        bottom:
                            sash.Neighbours.bottom.length === 0
                                ? -1
                                : sash.Neighbours.top.length + sash.Neighbours.middle.length - 1,
                    },
                });

                if (k > 0) {
                    layout.divs[Number(s.position) - 1] = {
                        id: Number(s.position) - 1,
                        direction: 'vertical',
                        position: (1 / sash.Neighbours.middle.length) * k,
                        length: 1,
                        sashes: {
                            top: [],
                            bottom: [],
                            left: [Number(s.position) - 1],
                            right: [Number(s.position)],
                        },
                        divs: {
                            top: [],
                            bottom: [],
                            left: [],
                            right: [],
                        },
                    };
                    layoutBaseCode += '|';
                }
                layoutBaseCode += '[▣]';
            }
            if (sash.Neighbours.middle.length > 0 && sash.Neighbours.bottom.length > 0) {
                layoutBaseCode += ')_(';
            }

            for (k = 0; k < sash.Neighbours.bottom.length; k++) {
                s = sash.Neighbours.bottom[k];
                layout.sashes.push({
                    id: Number(s.position),
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height: 0.2,
                    width: 1 / sash.Neighbours.bottom.length,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors: {
                        top: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                        left:
                            k === 0 || sash.Neighbours.bottom.length === 1
                                ? []
                                : [Number(s.position) - 1],
                        right:
                            k + 1 === sash.Neighbours.bottom.length
                            || sash.Neighbours.bottom.length === 1
                                ? []
                                : [Number(s.position) + 1],
                        bottom: [],
                    },
                    divs: {
                        top: sash.SashesLayout.all_sashes_count - sash.Neighbours.bottom.length - 1,
                        left:
                            k === 0 || sash.Neighbours.bottom.length === 1
                                ? -1
                                : Number(s.position) - 1,
                        right:
                            k + 1 === sash.Neighbours.bottom.length
                            || sash.Neighbours.bottom.length === 1
                                ? -1
                                : Number(s.position),
                        bottom: -1,
                    },
                });

                if (k > 0) {
                    layout.divs[Number(s.position) - 1] = {
                        id: Number(s.position) - 1,
                        direction: 'vertical',
                        position: (1 / sash.Neighbours.bottom.length) * k,
                        length: 1,
                        sashes: {
                            top: [],
                            bottom: [],
                            left: [Number(s.position) - 1],
                            right: [Number(s.position)],
                        },
                        divs: {
                            top: [],
                            bottom: [],
                            left: [],
                            right: [],
                        },
                    };
                    layoutBaseCode += '|';
                }
                layoutBaseCode += '[▣]';
            }
            if (sash.Neighbours.bottom.length > 0) {
                layoutBaseCode += ')';
            }

            if (sash.Neighbours.top.length > 0) {
                layout.divs[sash.Neighbours.top.length - 1] = {
                    id: sash.Neighbours.top.length - 1,
                    direction: 'horizontal',
                    position: 0.2,
                    length: 1,
                    sashes: {
                        top: sash.Neighbours.top.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                        bottom: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                        left: [],
                        right: [],
                    },
                    divs: {
                        top: core.range(0, sash.Neighbours.top.length - 2),
                        bottom: core.range(
                            sash.Neighbours.top.length,
                            sash.Neighbours.top.length + sash.Neighbours.middle.length - 2
                        ),
                        left: [],
                        right: [],
                    },
                };
            }

            if (sash.Neighbours.bottom.length > 0) {
                layout.divs[
                    sash.SashesLayout.all_sashes_count - sash.Neighbours.bottom.length - 1
                ] = {
                    id: sash.SashesLayout.all_sashes_count - sash.Neighbours.bottom.length - 1,
                    direction: 'horizontal',
                    position: 0.8,
                    length: 1,
                    sashes: {
                        top: sash.Neighbours.middle.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                        bottom: sash.Neighbours.bottom.map(function mapNeighbours(e) {
                            return Number(e.position);
                        }),
                        left: [],
                        right: [],
                    },
                    divs: {
                        top: core.range(
                            sash.Neighbours.top.length,
                            sash.Neighbours.top.length + sash.Neighbours.middle.length - 2
                        ),
                        bottom: core.range(
                            sash.Neighbours.top.length + sash.Neighbours.middle.length,
                            sash.Neighbours.top.length
                                + sash.Neighbours.middle.length
                                + sash.Neighbours.bottom.length
                                - 2
                        ),
                        left: [],
                        right: [],
                    },
                };
            }
            layout.layoutBaseCode = layoutBaseCode;
        }

        return layout;
    }

    /**
     * Funkcja przygotowania danych skrzydeł okna
     * @param  {Number} sashesCount Liczba skrzydeł
     * @param  {Object} sashType    Typ skrzydła
     * @return {String}             Typ
     */
    public prepareWindowSashesData(
        layout: Layout,
        conf: WindowActiveConfiguration | DoorActiveConfiguration = this.configurationsService.conf
            .Current,
        defaultConf: WindowActiveConfiguration | DoorActiveConfiguration = this
            .configurationsService.conf.Default
    ) {
        this.validationService.invalid(conf, 'sashes');
        this.validationService.invalid(conf, 'fillings');
        this.validationService.invalid(conf, 'frameProfiles');
        this.validationService.invalid(conf, 'sashesProfiles');
        this.validationService.invalid(conf, 'mullionsProfiles');

        const notDoorSash = (s) => ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(s.type.type) === -1;
        const isDoorLight = (s) => conf.type === 'door' && notDoorSash(s);
        const isTopLight = (s, frame) => isDoorLight(s) && frame.y + s.ry + s.rHeight < conf.Height;
        const sashWithAccessoryId: {
            accessoryPosition: 'left' |'door' | 'right' | 'top',
            hardware: any,
        }[] = [];
        conf.Sashes.forEach(sash => {
            if(sash.hardware.length > 0) {
                const frame = conf.Frames.find(f => f.id === sash.frameId);
                let accessoryPosition: 'left' |'door' | 'right' | 'top';
                if(!notDoorSash(sash)) {
                    accessoryPosition = 'door';
                } else if(isTopLight(sash, frame)) {
                    accessoryPosition = 'top';
                } else {
                    accessoryPosition = frame.x === 0 ? 'left' : 'right'
                }
                sash.hardware.forEach(hardware => {
                    sashWithAccessoryId.push({accessoryPosition, hardware});
                })
            }
        });
        conf.Sashes = [];
        conf.Mullions = [];
        conf.Frames = [];
        if (DoorActiveConfiguration.is(conf) && conf.System && conf.System.door_type) {
            conf.Width = this.sizesService.getConstructionWidthBasedOnLayout(layout, conf);
        }
        const sashes = this.createSashesFromLayout(
            layout,
            conf.Height,
            conf.Width,
            this.sashTypes,
            conf,
            DoorActiveConfiguration.is(conf) && conf.ModelOptions
        );
        const dividers = this.createMullionsFromLayout(layout, sashes);

        conf.Sashes = sashes;
        conf.EdgeSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        conf.Alignments = [];
        conf.Mullions = dividers;

        const pauseId = this.eventBusService.pause(['processDependencies']);

        if (DoorActiveConfiguration.is(conf) && conf.System && conf.System.door_type) {
            const shape = this.sizesService.getShapeFromDoorSizes(conf.doorSizes, conf);
            this.dimensionsService.setDimensions(shape, false);
        }

        this.framesService.setDefaultFrame(conf);
        if (['window', 'hs', 'door', 'folding_door', 'sliding_door'].includes(layout.conf)) {
            for (let i = 0; i < sashes.length; i++) {
                const sash = sashes[i];
                if (sash.nearMullions.left === -1) {
                    conf.EdgeSashes.left.push(sash.id);
                }
                if (sash.nearMullions.right === -1) {
                    conf.EdgeSashes.right.push(sash.id);
                }
                if (sash.nearMullions.top === -1) {
                    conf.EdgeSashes.top.push(sash.id);
                }
                if (sash.nearMullions.bottom === -1) {
                    conf.EdgeSashes.bottom.push(sash.id);
                }
            }
        }
        this.sashTypesService.refreshTypes(conf);
        Object.assign(conf, this.validationService.valid(conf, 'sashes'));
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        this.profilesService.setDefaultSashes(conf, defaultConf);


        if (conf.System.confType === 'hs' && this.config().IccConfig.Configurators.hs.layoutEqualDivision && layout.equalDivision) {
            this.equalDivisionService.equalDivision(conf.Mullions[0], conf, 'glazing', 1/2, true);
        }

        this.shapeService.setShapes(conf);
        this.muntinsService.hasMuntins(conf);
        this.sashesLayoutService.setIndex(conf);
        this.validateLayout();

        if(sashWithAccessoryId.length > 0) {
            this.accessoriesService.restoreAccessoriesInSashes(sashWithAccessoryId);
        }
        this.handlesService.checkIsOneHandleAndAllHasHandle(conf, true, defaultConf);
        this.handlesService.refreshTypes(conf);
        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.warrantyService.check(conf);
        this.parametersService.count(this.configurationsService.conf.Current);

        this.eventBusService.resume(['processDependencies'], pauseId);
    }

    /**
     * Funkcja wyboru szablonu skrzydeł okna
     * @param  {Number} sashesCount Liczba skrzydeł
     * @param  {Object} custom      custom
     */
    public selectWindowSashesLayout(sashesCount: Layout) {
        const conf = this.configurationsService.conf.Current;
        const frame = conf?.Frames[0];
        const sashes = sashesCount.sashes.map(el => el?.type);
        const isSashesLayoutValid = this.sashesTypesService.validateAllSashTypesInFrame(frame, undefined, sashes)
        if (!isSashesLayoutValid){
            this.issuesService.simpleRegister(
                'sash-type-invalid',
                'Niepoprawne profile ramy',
                this.translateService.instant('INTERFACE|Nie można dobrać tego układu do profilu ramy'),
                conf,
                {
                    level: IssueLevel.ERROR,
                    logLevel: IssueLevel.WARN,
                    blockStepsAfter: null,
                }
            );
        }
        if (
            !conf.System.door_type && sashesCount.sashes.some(
                sash => sash.width * conf.Width < 250 || sash.height * conf.Height < 250
            )
        ) {
            this.issuesService.simpleRegister(
                'incorrect-window-dimensions',
                'Podane wymiary są nieprawidłowe.',
                this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                this.configurationsService.conf.Current,
                {
                    logLevel: IssueLevel.NONE,
                }
            );
            return false;
        } else {

            const pauseId = this.eventBusService.pause(['processDependencies']);
            conf.Layout = sashesCount;
            // this.configurationsService.conf.Default.Layout = sashesCount;
            if (DoorActiveConfiguration.is(conf) && conf.System && conf.System.door_type) {
                this.sizesService.setDoorSizesBasedOnLayout(sashesCount, conf);
            }
            this.prepareWindowSashesData(sashesCount);

            this.locksService.findLocksBySystem();
            Object.assign(conf, this.validationService.valid(conf, 'shape'));
            this.issuesService.unregister('fixSashTypeAfterSetSystem', conf);
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });

            if (this.config().IccConfig.Configurators.dependencies) {
                this.eventBusService.post({ key: 'processDependencies', value: null });
            }

            this.eventBusService.resume(['processDependencies'], pauseId);
            return true;
        }
    }

    /**
     * Funkcja walidujca szablon
     * @param  {Object} supress Supress
     * @return {Object}         Zwalidowane
     */
    public validateLayout(supress?, showmodal?) {
        if (isUndefined(showmodal)) {
            showmodal = true;
        }

        if (
            !['window', 'hs', 'door', 'folding_door', 'sliding_door'].includes(
                this.configurationsService.conf.Current.type
            )
        ) {
            return true;
        }
        const divs = this.configurationsService.conf.Current.Mullions;
        if (this.configurationsService.conf.Current.Sashes.length === 0) {
            this.issuesService.simpleRegister(
                'no-sashes-layout',
                'Brak wybranego układu.',
                this.translateService.instant('WINDOW|Brak wybranego układu.'),
                this.configurationsService.conf.Current,
                {
                    showMessage: !supress,
                    logLevel: IssueLevel.NONE,
                }
            );
            return false;
        }
        this.issuesService.unregister('no-sashes-layout', this.configurationsService.conf.Current);
        let doorCount = 0;
        const shape = this.configurationsService.conf.Current.Shape;
        let allowedTypes, restrict;

        const falseMullionsMap = {
            DS: ['D', 'DK'],
            DSH: ['D', 'DK'],
            DSC: ['D', 'DK'],
            ODS: ['OD'],
            ODSH: ['OD'],
            DRP: ['DRA'],
            DOP: ['DOA'],
            PSK: ['PSK'],
            S: ['S'],
            HS: ['HS'],
        };

        for (let i = 0; i < this.configurationsService.conf.Current.Sashes.length; i++) {
            const sash = this.configurationsService.conf.Current.Sashes[i];
            const lDiv = divs[core.fId(divs, sash.nearMullions.left)];
            const rDiv = divs[core.fId(divs, sash.nearMullions.right)];
            const type = this.configurationsService.conf.Current.Sashes[i].type;

            if (falseMullionsMap[type.type] && type.passive) {
                if (
                    type.handle_position === 'R'
                    && (isUndefined(rDiv)
                        || rDiv.multiAlignRight.length !== 1
                        || rDiv.multiAlignRight[0].type.handle_position !== 'L'
                        || falseMullionsMap[type.type].indexOf(rDiv.multiAlignRight[0].type.type)
                            === -1
                        || rDiv.multiAlignRight[0].type.passive)
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }

                if (
                    type.handle_position === 'L'
                    && (isUndefined(lDiv)
                        || lDiv.multiAlignLeft.length !== 1
                        || lDiv.multiAlignLeft[0].type.handle_position !== 'R'
                        || falseMullionsMap[type.type].indexOf(lDiv.multiAlignLeft[0].type.type)
                            === -1
                        || lDiv.multiAlignLeft[0].type.passive)
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
            }

            if (['DRA', 'DRP', 'DOA', 'DOP'].indexOf(type.type) > -1) {
                doorCount++;
            }

            // dla koła
            // koło - jedno skrzydło
            if (
                shape.shape === 'circle'
                && sash.nearMullions.top === -1
                && sash.nearMullions.bottom === -1
                && sash.nearMullions.left === -1
                && sash.nearMullions.right === -1
            ) {
                allowedTypes = ['F', 'FF', 'OFF', 'K'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                    K: [230],
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
                if (
                    isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                        || (isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
            }

            // koło - dwa skrzydła ||
            if (
                shape.shape === 'circle'
                && sash.nearMullions.top === -1
                && sash.nearMullions.bottom === -1
                && (sash.nearMullions.left > -1 || sash.nearMullions.right > -1)
            ) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
                if (
                    isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                        || (isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
            }

            // koło - dwa skrzydła = - skrzydło górne
            if (
                shape.shape === 'circle'
                && sash.nearMullions.left === -1
                && sash.nearMullions.right === -1
                && sash.nearMullions.top === -1
                && sash.nearMullions.bottom > -1
            ) {
                allowedTypes = ['F', 'FF', 'OFF', 'K'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                    K: [230],
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
                if (
                    isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                        || (isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
            }

            // koło - dwa skrzydła = - skrzydło dolne
            if (
                shape.shape === 'circle'
                && sash.nearMullions.left === -1
                && sash.nearMullions.right === -1
                && sash.nearMullions.bottom === -1
                && sash.nearMullions.top > -1
            ) {
                allowedTypes = ['F', 'FF', 'OFF'];
                restrict = {
                    F: [150],
                    FF: [230],
                    OFF: [230],
                };
                if (allowedTypes.indexOf(type.type) === -1) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
                if (
                    isDefined(restrict[type.type])
                    && ((isDefined(restrict[type.type][0]) && restrict[type.type][0] > shape.d / 2)
                        || (isDefined(restrict[type.type][1])
                            && restrict[type.type][1] < shape.d / 2))
                ) {
                    this.issuesService.simpleRegister(
                        'incorrect-layout',
                        'Niepoprawny układ kwater.',
                        this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                        this.configurationsService.conf.Current,
                        {
                            showMessage: !supress,
                            logLevel: IssueLevel.NONE,
                        }
                    );
                    return false;
                }
            }
            if (['PSK'].indexOf(type.type) > -1 && sash.shape.shape !== 'rect') {
                this.issuesService.simpleRegister(
                    'psk-shape',
                    'Skrzydło przesuwne musi być prostokątem.',
                    this.translateService.instant(
                        'WINDOW|Skrzydło przesuwne musi być prostokątem.'
                    ),
                    this.configurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }

            if (
                this.config().IccConfig.Configurators.noHingesOnSlope
                && ['F', 'FF', 'OFF'].indexOf(type.type) === -1
                && ((type.handle_position === 'R' && sash.shape.angles.hasLeftSlopeSide)
                    || (type.handle_position === 'L' && sash.shape.angles.hasRightSlopeSide))
            ) {
                this.issuesService.simpleRegister(
                    'hinges-on-slope',
                    'Nie można dodawać okuć na elementach skośnych.',
                    this.translateService.instant(
                        'WINDOW|Nie można dodawać okuć na elementach skośnych.'
                    ),
                    this.configurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }

            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position === 'L'
                && (isUndefined(rDiv)
                    || rDiv.multiAlignRight.length !== 1
                    || (rDiv.multiAlignRight[0].type.type !== 'FF'
                        && rDiv.multiAlignRight[0].type.type !== 'OFF'
                        && rDiv.multiAlignRight[0].type.type !== 'HS'))
            ) {
                this.issuesService.simpleRegister(
                    'incorrect-layout',
                    'Niepoprawny układ kwater.',
                    this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                    this.configurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }
            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position === 'R'
                && (isUndefined(lDiv)
                    || lDiv.multiAlignLeft.length !== 1
                    || (lDiv.multiAlignLeft[0].type.type !== 'FF'
                        && lDiv.multiAlignLeft[0].type.type !== 'OFF'
                        && lDiv.multiAlignLeft[0].type.type !== 'HS'))
            ) {
                this.issuesService.simpleRegister(
                    'incorrect-layout',
                    'Niepoprawny układ kwater.',
                    this.translateService.instant('WINDOW|Niepoprawny układ kwater.'),
                    this.configurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }

            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position === 'L'
                && (sash.rWidth > rDiv.multiAlignRight[0].rWidth + 6
                    && (rDiv.multiAlignRight[0].type.type === 'FF'
                        || rDiv.multiAlignRight[0].type.type === 'OFF'))
            ) {
                this.issuesService.simpleRegister(
                    'hs-width',
                    'Skrzydło przesuwne nie może być szersze niż skrzydło stałe.',
                    this.translateService.instant(
                        'WINDOW|Skrzydło przesuwne nie może być szersze niż skrzydło stałe.'
                    ),
                    this.configurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }
            if (
                ['HS'].indexOf(type.type) > -1
                && type.handle_position === 'R'
                && (sash.rWidth > lDiv.multiAlignLeft[0].rWidth + 6
                    && (lDiv.multiAlignLeft[0].type.type === 'FF'
                        || lDiv.multiAlignLeft[0].type.type === 'OFF'))
            ) {
                this.issuesService.simpleRegister(
                    'hs-width',
                    'Skrzydło przesuwne nie może być szersze niż skrzydło stałe.',
                    this.translateService.instant(
                        'WINDOW|Skrzydło przesuwne nie może być szersze niż skrzydło stałe.'
                    ),
                    this.configurationsService.conf.Current,
                    {
                        showMessage: !supress,
                        logLevel: IssueLevel.NONE,
                    }
                );
                return false;
            }
        }
        if (
            doorCount === 0
            && this.currentConfiguratorService.conf === 'door'
            && !this.config().IccConfig.Configurators.door.singleFix
        ) {
            this.issuesService.simpleRegister(
                'no-door-sashes',
                'Brak skrzydeł drzwiowych w układzie.',
                this.translateService.instant('DOOR|Brak skrzydeł drzwiowych w układzie.'),
                this.configurationsService.conf.Current,
                {
                    showMessage: !supress,
                    logLevel: IssueLevel.NONE,
                }
            );
            return false;
        }
        this.issuesService.unregister('incorrect-layout', this.configurationsService.conf.Current);
        this.issuesService.unregister('no-sashes-layout', this.configurationsService.conf.Current);
        this.issuesService.unregister('psk-shape', this.configurationsService.conf.Current);
        this.issuesService.unregister('hinges-on-slope', this.configurationsService.conf.Current);
        this.issuesService.unregister('hs-width', this.configurationsService.conf.Current);
        this.issuesService.unregister('no-door-sashes', this.configurationsService.conf.Current);
        return true;
    }

    /**
     * Rekalkulacja
     */
    public recalc() {
        this.priceService.count();
    }

    /**
     * Funkcja przygotowania danych skrzydeł okna
     * @param  {Number} sashesCount Liczba skrzydeł
     * @param  {Object} sashType    Typ skrzydła
     * @return {String}             Typ
     */
    public resetDimensions(sashesCount, conf = this.configurationsService.conf.Current) {
        const newConf = this.resetLayoutDimensions(sashesCount, conf);
        Object.assign(conf, newConf);

        this.framesService.resetFrameDimensions(conf);
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        this.shapeService.setShapes(conf);
        this.muntinsService.hasMuntins(conf);
        this.sashesLayoutService.setIndex(conf);

        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.warrantyService.check(conf);
        this.parametersService.count(conf);
    }

    /**
     * Funkcja przygotowania danych skrzydeł okna
     * @param  {Number} layout Liczba skrzydeł
     * @param  {Object} sashType    Typ skrzydła
     * @return {String}             Typ
     */
    public resetLayoutDimensions(
        layout: Readonly<Layout>,
        conf: Readonly<IWindowActiveConfiguration>
    ): IWindowActiveConfiguration {
        let sashesSchema = layout.sashes;
        let divsSchema = layout.divs;
        if (layout.fromCode && layout.layoutCode) {
            const schema = core.copy(layout.layoutCode);
            const schemaSashes: LayoutSash[] = [];
            const schemaDivs: LayoutDiv[] = [];
            const error = { value: 0 };
            let index = { i: 0 };
            this.indexSashes(schema, index);
            index = { i: 0 };
            this.prepareDividersFromCode(schema, schemaDivs, index);
            schemaDivs.forEach(div => {
                if (div.sashes[div.direction === 'horizontal' ? 'top' : 'left'].length >= 2) {
                    for (
                        let i = 0;
                        i < div.sashes[div.direction === 'horizontal' ? 'top' : 'left'].length - 1;
                        i++
                    ) {
                        const sashId = schemaDivs.find(
                            el =>
                                el.sashes[div.direction === 'horizontal' ? 'left' : 'top'].indexOf(
                                    div.sashes[div.direction === 'horizontal' ? 'top' : 'left'][i]
                                ) > -1
                                && el.sashes[
                                    div.direction === 'horizontal' ? 'right' : 'bottom'
                                ].indexOf(
                                    div.sashes[div.direction === 'horizontal' ? 'top' : 'left'][
                                        i + 1
                                    ]
                                ) > -1
                        );
                        if (sashId) {
                            div.divs[div.direction === 'horizontal' ? 'top' : 'left'].push(
                                sashId.id
                            );
                        }
                    }
                }
                if (div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'].length >= 2) {
                    for (
                        let i = 0;
                        i
                        < div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'].length
                            - 1;
                        i++
                    ) {
                        const sashId = schemaDivs.find(
                            el =>
                                el.sashes[div.direction === 'horizontal' ? 'left' : 'top'].indexOf(
                                    div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'][
                                        i
                                    ]
                                ) > -1
                                && el.sashes[
                                    div.direction === 'horizontal' ? 'right' : 'bottom'
                                ].indexOf(
                                    div.sashes[div.direction === 'horizontal' ? 'bottom' : 'right'][
                                        i + 1
                                    ]
                                ) > -1
                        );
                        if (sashId) {
                            div.divs[div.direction === 'horizontal' ? 'bottom' : 'right'].push(
                                sashId.id
                            );
                        }
                    }
                }
            });
            this.prepareLayoutFromCode(
                schema,
                conf.Width,
                conf.Height,
                conf.Width,
                conf.Height,
                schemaDivs,
                layout.sashTypes,
                schemaSashes,
                error
            );

            if (error.value) {
                this.infoService.openWarning(
                    this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.')
                );
            } else {
                sashesSchema = schemaSashes;
                divsSchema = schemaDivs;
            }
        }

        let sumWidthTop = 0;
        let sumWidthMiddle = 0;
        let sumWidthBottom = 0;
        let sumHeight = 0;
        const sashes: ActiveSash[] = [];
        let rx: number, ry: number;

        const newConf = {
            ...conf,
        };

        sashesSchema.forEach(sashSchema => {
            const sash = conf.Sashes.find(s => s.id === sashSchema.id);
            if (sash) {
                const resizedSash: ActiveSash = sash;

                if (sashSchema.neighbors.left.length === 0 || sashes.length === 0) {
                    rx = 0;
                } else {
                    rx =
                        sashes[sashSchema.neighbors.left[0]].rx
                        + sashes[sashSchema.neighbors.left[0]].rWidth;
                }
                if (sashSchema.neighbors.top.length === 0 || sashes.length === 0) {
                    ry = 0;
                } else {
                    ry =
                        sashes[sashSchema.neighbors.top[0]].ry
                        + sashes[sashSchema.neighbors.top[0]].rHeight;
                }

                let sashWidth = Math.round(conf.Width * sashSchema.width);
                let sashHeight = Math.round(conf.Height * sashSchema.height);
                if (DoorActiveConfiguration.is(conf) && conf.System && conf.System.door_type) {
                    sashWidth = this.sizesService.getSashWidth(resizedSash, conf);
                    sashHeight = this.sizesService.getSashHeight(resizedSash, conf);
                }
                resizedSash.rx = rx;
                resizedSash.ry = ry;
                resizedSash.rWidth = sashWidth;
                resizedSash.rHeight = sashHeight;
                resizedSash.frameId = 0;
                if (sashSchema.divs.top === -1 && sashSchema.divs.bottom !== -1) {
                    sumWidthTop += resizedSash.rWidth;
                } else if (sashSchema.divs.bottom === -1 && sashSchema.divs.top !== -1) {
                    sumWidthBottom += resizedSash.rWidth;
                } else {
                    sumWidthMiddle += resizedSash.rWidth;
                }

                if (sashSchema.divs.left === -1) {
                    sumHeight += resizedSash.rHeight;
                }
                sashes.push(resizedSash);
            }
        });

        if (!layout.fromCode) {
            if (sumWidthTop !== conf.Width && sumWidthTop > 0) {
                const topSashes = sashes.filter(
                    s => s.nearMullions.top === -1 && s.nearMullions.bottom !== -1
                );
                topSashes[topSashes.length - 1].rWidth += conf.Width - sumWidthTop;
            }
            if (sumWidthBottom !== conf.Width && sumWidthBottom > 0) {
                const bottomSashes = sashes.filter(
                    s => s.nearMullions.bottom === -1 && s.nearMullions.top !== -1
                );
                bottomSashes[bottomSashes.length - 1].rWidth += conf.Width - sumWidthBottom;
            }
            if (sumWidthMiddle !== conf.Width && sumWidthMiddle > 0) {
                const middleSashes = sashes.filter(
                    s =>
                        (s.nearMullions.top !== -1 && s.nearMullions.bottom !== -1)
                        || (s.nearMullions.top === -1 && s.nearMullions.bottom === -1)
                );
                middleSashes[middleSashes.length - 1].rWidth += conf.Width - sumWidthMiddle;
            }
            if (sumHeight !== conf.Height && sumHeight > 0) {
                sashes
                    .filter(s => s.nearMullions.bottom === -1 && s.nearMullions.top !== -1)
                    .map(s => {
                        s.rHeight += conf.Height - sumHeight;
                    });
            }
        }

        sashes.forEach(s => {
            if (s.intSashes && s.intSashes.length > 0) {
                s.intSashes[0].rx = 0;
                s.intSashes[0].ry = 0;
                s.intSashes[0].rWidth = s.rWidth;
                s.intSashes[0].rHeight = s.rHeight;
            }
        });

        newConf.Sashes = sashes;

        if (divsSchema) {
            const mullions: ActiveMullion[] = [];
            divsSchema.forEach(mullionSchema => {
                const divider = conf.Mullions.find(m => m.id === mullionSchema.id);
                if (divider) {
                    const resizedMullion = divider;
                    if (mullionSchema.direction === 'horizontal') {
                        resizedMullion.rx = sashes[mullionSchema.sashes.top[0]].rx;
                        resizedMullion.ry =
                            sashes[mullionSchema.sashes.top[0]].ry
                            + sashes[mullionSchema.sashes.top[0]].rHeight;
                        resizedMullion.rWidth = mullionSchema.sashes.top.reduce(
                            (prev, curr) => prev + sashes[curr].rWidth,
                            0
                        );
                        resizedMullion.rHeight = 1;
                    } else {
                        resizedMullion.rx =
                            sashes[mullionSchema.sashes.left[0]].rx
                            + sashes[mullionSchema.sashes.left[0]].rWidth;
                        resizedMullion.ry = sashes[mullionSchema.sashes.left[0]].ry;
                        resizedMullion.rHeight = mullionSchema.sashes.left.reduce(
                            (prev, curr) => prev + sashes[curr].rHeight,
                            0
                        );
                    }
                    mullions.push(resizedMullion);
                }
            });
            newConf.Mullions = mullions;
        }
        return newConf;
    }

    public replaceSashTypes(
        layout: Layout,
        sashTypes: IccSashType[],
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        console.log('replaceSashTypes');
        let sashesSchema = layout.sashes;
        if (layout.fromCode) {
            const schema = core.copy(layout.layoutCode);
            const schemaSashes = [];
            const schemaDivs = [];
            const error = { value: 0 };
            let index = { i: 0 };
            this.indexSashes(schema, index);
            index = { i: 0 };
            this.prepareDividersFromCode(schema, schemaDivs, index);
            this.prepareLayoutFromCode(
                schema,
                conf.Width,
                conf.Height,
                conf.Width,
                conf.Height,
                schemaDivs,
                layout.sashTypes,
                schemaSashes,
                error
            );

            if (error.value) {
                this.infoService.openWarning(
                    this.translateService.instant('CONFIGURATOR|Podane wymiary są nieprawidłowe.')
                );
            } else {
                sashesSchema = schemaSashes;
            }
        }

        sashesSchema.forEach(sashSchema => {
            const sash = core.fIdO(conf.Sashes, sashSchema.id);
            sash.type = this.getSashType(sashSchema, sashTypes);
        });
        conf.Layout = layout;
        this.sashTypesService.refreshTypes(conf);
        Object.assign(conf, this.validationService.valid(conf, 'sashes'));
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        this.shapeService.setShapes(conf);
        this.muntinsService.hasMuntins(conf);
        this.sashesLayoutService.setIndex(conf);
        this.validateLayout();

        this.handlesService.checkIsOneHandleAndAllHasHandle(conf, true, defaultConf);
        this.handlesService.refreshTypes(conf);
        this.constructionLimitationService.findReinforcement(conf);
        this.priceService.count();
        this.warrantyService.check(conf);
        this.parametersService.count(conf);
    }

    public resetLayout(noEvent = false) {
        this.configurationsService.conf.Current.Layout = null;
        this.configurationsService.conf.Current.Sashes = [];
        this.configurationsService.conf.Current.EdgeSashes = {
            top: [],
            bottom: [],
            left: [],
            right: [],
        };
        this.priceService.count();
        if (!noEvent) {
            this.eventBusService.post({
                key: 'changedSashes',
                value: {},
            });
        }
    }

    createSashesFromLayout(
        layout: Layout,
        height: number,
        width: number,
        sashTypes: IccSashType[],
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        modelOptions?: DoorActiveConfiguration['ModelOptions']
    ): ActiveSash[] {
        const sashes: ActiveSash[] = [];
        let sumHeight = 0;
        let sumWidthTop = 0;
        let sumWidthBottom = 0;
        let sumWidthMiddle = 0;

        layout.sashes.forEach(sashSchema => {
            let x: number;
            if (sashSchema.neighbors.left.length === 0 || sashes.length === 0) {
                x = 0;
            } else {
                x =
                    sashes[sashSchema.neighbors.left[0]].rx
                    + sashes[sashSchema.neighbors.left[0]].rWidth;
            }
            let y: number;
            if (sashSchema.neighbors.top.length === 0 || sashes.length === 0) {
                y = 0;
            } else {
                y =
                    sashes[sashSchema.neighbors.top[0]].ry
                    + sashes[sashSchema.neighbors.top[0]].rHeight;
            }
            let panelInner = core.copy(modelOptions && modelOptions?.panelInner);
            let panelGlazing =  core.copy((modelOptions && modelOptions.panelGlazing) || undefined);

            if(['DRP', 'DOP'].indexOf(sashSchema.type) > -1 && modelOptions?.passiveInnerSashModel) {
                panelInner = modelOptions.passiveInnerSashModel
            }
            if(['DRP', 'DOP'].indexOf(sashSchema.type) > -1 && modelOptions?.passivePanelGlazing) {
                panelGlazing = modelOptions.passivePanelGlazing
            }

            if (!['DRA', 'DRP', 'DOA', 'DOP'].includes(sashSchema.type)) {
                panelInner = null;
            }

            const sash = new ActiveSash(sashSchema.id, {
                frameId: 0,
                index: sashSchema.id,
                rx: x,
                ry: y,
                rWidth: Math.round(width * sashSchema.width),
                rHeight: Math.round(height * sashSchema.height),
                type: this.getSashType(sashSchema, sashTypes),
                divs: {
                    left: sashSchema.divs.left,
                    right: sashSchema.divs.right,
                    top: sashSchema.divs.top,
                    bottom: sashSchema.divs.bottom,
                },
                panelType: (modelOptions && modelOptions.panelType) || 'Inset',
                panelGlazing,
                panelInner,
            });
            if (DoorActiveConfiguration.is(conf) && conf.System && conf.System.door_type) {
                const leftNeightborType =
                    sashSchema.neighbors.left.length > 0
                    && this.getSashType(layout.sashes[sashSchema.neighbors.left[0]], sashTypes);
                const rightNeightborType =
                    sashSchema.neighbors.right.length > 0
                    && this.getSashType(layout.sashes[sashSchema.neighbors.right[0]], sashTypes);
                sash.rWidth = this.sizesService.getSashWidth(
                    sash,
                    conf,
                    leftNeightborType,
                    rightNeightborType
                );
                sash.rHeight = this.sizesService.getSashHeight(sash, conf);
            }

            sash.frame = {
                bottom: this.getDefaultFrameProfile(sashSchema.frame.top, 0),
                right: this.getDefaultFrameProfile(sashSchema.frame.top, 1),
                top: this.getDefaultFrameProfile(sashSchema.frame.top, 2),
                left: this.getDefaultFrameProfile(sashSchema.frame.top, 3),
            };

            if (sashSchema.divs.top === -1 && sashSchema.divs.bottom !== -1) {
                sumWidthTop += sash.rWidth;
            } else if (sashSchema.divs.bottom === -1 && sashSchema.divs.top !== -1) {
                sumWidthBottom += sash.rWidth;
            } else {
                if (
                    !(
                        sashSchema.divs.top > -1
                        && layout.divs[sashSchema.divs.top].sashes.top.length
                        && layout.divs[sashSchema.divs.top].sashes.top.some(
                            el => layout.sashes[el].divs.top > -1
                        )
                    )
                ) {
                    sumWidthMiddle += sash.rWidth;
                }
            }

            if (sashSchema.divs.left === -1) {
                sumHeight += sash.rHeight;
            }
            sashes.push(sash);
        });

        if (
            !layout.fromCode
            && !(DoorActiveConfiguration.is(conf) && conf.System && conf.System.door_type)
        ) {
            if (sumWidthTop !== width && sumWidthTop > 0) {
                const topSashes = sashes.filter(
                    s => s.nearMullions.top === -1 && s.nearMullions.bottom !== -1
                );
                topSashes[topSashes.length - 1].rWidth += width - sumWidthTop;
            }
            if (sumWidthBottom !== width && sumWidthBottom > 0) {
                const bottomSashes = sashes.filter(
                    s => s.nearMullions.bottom === -1 && s.nearMullions.top !== -1
                );
                bottomSashes[bottomSashes.length - 1].rWidth += width - sumWidthBottom;
            }
            if (sumWidthMiddle !== width && sumWidthMiddle > 0) {
                const middleSashes = sashes.filter(
                    s =>
                        (s.nearMullions.top !== -1 && s.nearMullions.bottom !== -1)
                        || (s.nearMullions.top === -1 && s.nearMullions.bottom === -1)
                );
                middleSashes[middleSashes.length - 1].rWidth += width - sumWidthMiddle;
            }
            if (sumHeight !== height && sumHeight > 0) {
                sashes
                    .filter(s => s.nearMullions.bottom === -1 && s.nearMullions.top !== -1)
                    .map(s => {
                        s.rHeight += height - sumHeight;
                    });
            }
        }

        return sashes;
    }

    getSashType(sashSchema: LayoutSash, sashTypes: IccSashType[]) {
        const foundType = sashTypes.find(
            el =>
                (el.type === sashSchema.type || (el.type === 'S' && sashSchema.type === 'SP'))
                && ((sashSchema.side !== 'R' && sashSchema.side !== 'L')
                    || (sashSchema.side === 'R' && el.handle_position === 'L')
                    || (sashSchema.side === 'L' && el.handle_position === 'R'))
                && ((sashSchema.type === 'S' && !Boolean(el.passive))
                    || (sashSchema.type === 'SP' && Boolean(el.passive))
                    || (['PSK', 'HS', 'DRA', 'DRP', 'DOA', 'DOP'].includes(sashSchema.type)
                        && sashSchema.active === !Boolean(el.passive))
                    || !['PSK', 'HS', 'DRA', 'DRP', 'DOA', 'DOP', 'S', 'SP'].includes(
                        sashSchema.type
                    ))
        );
        if (foundType) {
            const sashType: IccSashType = foundType;
            return sashType;
        }
    }

    getDefaultFrameProfile(frameProfileId: number | null, index: number) {
        const frameProfile: FrameProfile = {
            id: index,
            isDefault: true,
            jointAngle: 'E',
            profileId: Number(frameProfileId),
            weldFinishType: 'groove',
            reinforcement: null,
        };
        return frameProfile;
    }

    createMullionsFromLayout(layout: Layout, sashes: ActiveSash[]) {
        const dividers: ActiveMullion[] = [];
        layout.divs.forEach(mullionSchema => {
            let divider: ActiveMullion;
            const mAT: ActiveSash[] = [];
            for (let j = 0; j < mullionSchema.sashes.top.length; j++) {
                mAT.push(sashes[mullionSchema.sashes.top[j]]);
            }
            const mAB: ActiveSash[] = [];
            for (let j = 0; j < mullionSchema.sashes.bottom.length; j++) {
                mAB.push(sashes[mullionSchema.sashes.bottom[j]]);
            }
            const mAL: ActiveSash[] = [];
            for (let j = 0; j < mullionSchema.sashes.left.length; j++) {
                mAL.push(sashes[mullionSchema.sashes.left[j]]);
            }
            const mAR: ActiveSash[] = [];
            for (let j = 0; j < mullionSchema.sashes.right.length; j++) {
                mAR.push(sashes[mullionSchema.sashes.right[j]]);
            }
            divider = new ActiveMullion(mullionSchema.id, {
                rx:
                    mullionSchema.direction === 'horizontal'
                        ? sashes[mullionSchema.sashes.top[0]].rx
                        : sashes[mullionSchema.sashes.left[0]].rx
                          + sashes[mullionSchema.sashes.left[0]].rWidth,
                ry:
                    mullionSchema.direction === 'horizontal'
                        ? sashes[mullionSchema.sashes.top[0]].ry
                          + sashes[mullionSchema.sashes.top[0]].rHeight
                        : sashes[mullionSchema.sashes.left[0]].ry,
                rWidth:
                    mullionSchema.direction === 'horizontal'
                        ? mullionSchema.sashes.top.reduce(
                              (prev, curr) => prev + sashes[curr].rWidth,
                              0
                          )
                        : 1,
                rHeight:
                    mullionSchema.direction === 'horizontal'
                        ? 1
                        : mullionSchema.sashes.left.reduce(
                              (prev, curr) => prev + sashes[curr].rHeight,
                              0
                          ),
                frameId: 0,
                multiAlignLeft: mAL,
                multiAlignRight: mAR,
                multiAlignTop: mAT,
                multiAlignBottom: mAB,
                multiAlignLeftDiv: [],
                multiAlignRightDiv: [],
                multiAlignTopDiv: [],
                multiAlignBottomDiv: [],
                direction: mullionSchema.direction,
            });
            dividers.push(divider);
        });
        layout.divs.forEach((mullionSchema, i) => {
            const div = dividers[i];
            const mAT: ActiveMullion[] = [];
            for (let j = 0; j < mullionSchema.divs.top.length; j++) {
                mAT.push(dividers[mullionSchema.divs.top[j]]);
            }
            const mAB: ActiveMullion[] = [];
            for (let j = 0; j < mullionSchema.divs.bottom.length; j++) {
                mAB.push(dividers[mullionSchema.divs.bottom[j]]);
            }
            const mAL: ActiveMullion[] = [];
            for (let j = 0; j < mullionSchema.divs.left.length; j++) {
                mAL.push(dividers[mullionSchema.divs.left[j]]);
            }
            const mAR: ActiveMullion[] = [];
            for (let j = 0; j < mullionSchema.divs.right.length; j++) {
                mAR.push(dividers[mullionSchema.divs.right[j]]);
            }
            div.multiAlignTopDiv = mAT;
            div.multiAlignBottomDiv = mAB;
            div.multiAlignLeftDiv = mAL;
            div.multiAlignRightDiv = mAR;
        });

        return dividers;
    }

    indexSashes(branch: LayoutFromCode, index: { i: number }) {
        branch.sashesIds = [];
        for (let i = 0; i < branch.dimensions.length; i++) {
            const child = branch.branches[i];
            if (child) {
                branch.sashesIds.push(-1);
                this.indexSashes(child, index);
            } else {
                branch.sashesIds.push(index.i);
                index.i++;
            }
        }
    }

    prepareLayoutFromCode(
        branch: LayoutFromCode,
        width: number,
        height: number,
        constructionWidth: number,
        constructionHeight: number,
        allDivs: LayoutDiv[],
        sashTypes: LayoutSashType[],
        sashes: LayoutSash[],
        error: { value: boolean | number }
    ) {
        const wholeDimension = branch.division === '_' ? height : width;
        let remainingDimension = wholeDimension;
        let flexibleLength = 0;
        let flexibleLengthValue = 0;
        branch.layoutBaseCode = '';

        branch.dimensions.forEach(el => {
            if (String(el).includes('mm')) {
                remainingDimension -= Number(String(el).replace('mm', ''));
            } else {
                flexibleLength += Number(el);
            }
        });
        if (flexibleLength > 0) {
            flexibleLengthValue = remainingDimension / flexibleLength;
        }
        let noMMIndex = 0;
        let setDimension = 0;
        branch.dimensions.forEach((el, index) => {
            if (String(el).includes('mm')) {
                branch.dimensions[index] = Number(String(el).replace('mm', ''));
            } else {
                noMMIndex = index;
                branch.dimensions[index] = Math.round(flexibleLengthValue * Number(el));
                if (branch.dimensions[index] < this.config().IccConfig.Configurators.minWidth) {
                    error.value = true;
                }
            }
            setDimension += Number(branch.dimensions[index]);
        });
        if (setDimension !== wholeDimension && setDimension > 0) {
            (branch.dimensions[noMMIndex] as number) += wholeDimension - setDimension;
        }

        for (let i = 0; i < branch.dimensions.length; i++) {
            const sashId = branch.sashesIds[i];
            const subBranch = branch.branches[i];
            if (sashId > -1) {
                const s = sashTypes[sashId];
                const neighbors: {
                    top: number[];
                    left: number[];
                    right: number[];
                    bottom: number[];
                } = {
                    top: [],
                    left: [],
                    right: [],
                    bottom: [],
                };
                const divs = {
                    top: -1,
                    left: -1,
                    right: -1,
                    bottom: -1,
                };

                allDivs.forEach(div => {
                    if (div.sashes.bottom.indexOf(sashId) > -1) {
                        divs.top = div.id;
                        neighbors.top.push(...div.sashes.top);
                    } else if (div.sashes.top.indexOf(sashId) > -1) {
                        divs.bottom = div.id;
                        neighbors.bottom.push(...div.sashes.bottom);
                    } else if (div.sashes.left.indexOf(sashId) > -1) {
                        divs.right = div.id;
                        neighbors.right.push(...div.sashes.right);
                    } else if (div.sashes.right.indexOf(sashId) > -1) {
                        divs.left = div.id;
                        neighbors.left.push(...div.sashes.left);
                    }
                });

                branch.layoutBaseCode += (i > 0 ? branch.division : '') + '[▣]';

                sashes.push({
                    id: sashId,
                    type: s.type,
                    active: !s.passive,
                    side: s.handle_position === 'L' ? 'R' : s.handle_position === 'R' ? 'L' : null,
                    height:
                        Number(branch.division === '_' ? branch.dimensions[i] : height)
                        / constructionHeight,
                    width:
                        Number(branch.division === '|' ? branch.dimensions[i] : width)
                        / constructionWidth,
                    level: s.level,
                    frame: {
                        top: null,
                        bottom: null,
                        left: null,
                        right: null,
                    },
                    neighbors,
                    divs,
                });
            } else if (subBranch) {
                this.prepareLayoutFromCode(
                    subBranch,
                    Number(branch.division === '|' ? branch.dimensions[i] : width),
                    Number(branch.division === '_' ? branch.dimensions[i] : height),
                    constructionWidth,
                    constructionHeight,
                    allDivs,
                    sashTypes,
                    sashes,
                    error
                );
                branch.layoutBaseCode +=
                    (i > 0 ? branch.division : '') + '(' + subBranch.layoutBaseCode + ')';
            }
        }
    }

    prepareDividersFromCode(branch: LayoutFromCode, allDivs: LayoutDiv[], divId: { i: number }) {
        const direction = branch.division === '_' ? 'horizontal' : 'vertical';
        for (let i = 0; i < branch.branches.length - 1; i++) {
            const topLeftSashes = [];
            const bottomRightSashes = [];
            const subBranch = branch.branches[i];
            const subBranch2 = branch.branches[i + 1];
            const subBranchDirection =
                subBranch && (subBranch.division === '_' ? 'horizontal' : 'vertical');
            const subBranch2Direction =
                subBranch2 && (subBranch2.division === '_' ? 'horizontal' : 'vertical');
            if (!subBranch) {
                topLeftSashes.push(branch.sashesIds[i]);
            } else {
                subBranch.branches.forEach((el, j) => {
                    if (!el) {
                        if (subBranchDirection !== direction || j === 0) {
                            topLeftSashes.push(subBranch.sashesIds[j]);
                        }
                    } else {
                        topLeftSashes.push(el.sashesIds[el.sashesIds.length - 1]);
                    }
                });
            }
            if (!subBranch2) {
                bottomRightSashes.push(branch.sashesIds[i + 1]);
            } else {
                subBranch2.branches.forEach((el, j) => {
                    if (!el) {
                        if (subBranch2Direction !== direction || j === 0) {
                            bottomRightSashes.push(subBranch2.sashesIds[j]);
                        }
                    } else {
                        bottomRightSashes.push(el.sashesIds[0]);
                    }
                });
            }
            allDivs.push({
                id: divId.i,
                position: 1,
                length: 1,
                sashes: {
                    top: direction === 'horizontal' ? topLeftSashes : [],
                    bottom: direction === 'horizontal' ? bottomRightSashes : [],
                    left: direction === 'vertical' ? topLeftSashes : [],
                    right: direction === 'vertical' ? bottomRightSashes : [],
                },
                divs: {
                    top: [],
                    bottom: [],
                    left: [],
                    right: [],
                },
                direction,
            });
            divId.i++;
        }
        branch.branches.forEach(el => {
            if (el) {
                this.prepareDividersFromCode(el, allDivs, divId);
            }
        });
    }

    validAndFixDoorLayout(conf = this.configurationsService.conf.Current) {
        if (conf.type !== 'door'
            || !conf.System
            || !conf.System.door_type
        ) {
            return true;
        }
        const invalidLeftLight = conf.OwnedSashesTypes.doorLeftLight && !conf.doorSizes.leftLightSize && !conf.doorSizes.leftLightSizeId;
        const invalidRightLight = conf.OwnedSashesTypes.doorRightLight && !conf.doorSizes.rightLightSize && !conf.doorSizes.rightLightSizeId;
        const invalidTopLight = conf.OwnedSashesTypes.doorTopLight && !conf.doorSizes.topLightSize && !conf.doorSizes.topLightSizeId;
        const invalidPassiveSash = conf.OwnedSashesTypes.doorPassive && !conf.doorSizes.passiveSashSize && !conf.doorSizes.passiveSashSizeId;

        const layouts = this.getLayouts(conf);
        const isSelectedLayoutStillAvailable = Boolean(!conf.Layout?.id || conf.Layout.id == 0 || layouts.find(layout => {
            return layout.SashesLayoutsVariant?.id == conf.Layout.id
        }));

        if (invalidLeftLight || invalidRightLight || invalidTopLight || invalidPassiveSash || !isSelectedLayoutStillAvailable ) {
            const {doorSideWZ, doorSideLR} = this.getDoorSides();
            this.resetLayout(true);
            this.selectDefaultLayout(doorSideWZ, doorSideLR, layouts[0]);
        }

    }

    filterLayoutsForDoorSide(doorSide: 'W_L' | 'W_R' | 'Z_L' | 'Z_R', layout: any) {
        if (this.configurationsService.conf.Current.type === 'door') {
            const doorSideWZ =
                (layout.Neighbours && layout.Neighbours.middle.some(s => s.type === 'DOA') || layout.Sashes && layout.Sashes.some(s => s.type === 'DOA'))
                    ? 'Z'
                    : 'W';
            const doorSideLR =
                (layout.Neighbours
                    && layout.Neighbours.middle.some(
                        s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                    ) || layout.Sashes
                    && layout.Sashes.some(
                        s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                    ))
                    ? 'L'
                    : 'R';
            return (
                this.config().IccConfig.Configurators.showMismatchedVariants
                || (!doorSide || doorSide === doorSideWZ + '_' + doorSideLR)
            );
        } else {
            return true;
        }
    }

    matchDefaultLayoutToSelectedOne(sideWZ: 'W' | 'Z', sideLR: 'L' | 'R', oldLayout, conf = this.configurationsService.conf.Current) {
        let matchingLayouts = this.getLayouts(conf);
        const currentLayoutInfo = this.getDoorLayoutInfo(this.getSelectedLayout(conf, oldLayout));

        const blockedSashLayoutVariants = this.getBlockedSashLayoutVariants();

        if (blockedSashLayoutVariants.length) {
            let doorSize = null;

            if (sideWZ && sideLR) {
                doorSize = sideWZ + "_" + sideLR
            } else {
                const {doorSideWZ, doorSideLR} = this.getDoorSides()
                doorSize = doorSideWZ + "_" + doorSideLR;
            }

            matchingLayouts = matchingLayouts.filter(this.filterLayoutsForDoorSide.bind(this, doorSize))
        }

        const sameSideLayouts = matchingLayouts.filter((layout) => {
            if (blockedSashLayoutVariants.length && !this.isSashLayoutBlocked(layout, blockedSashLayoutVariants, this.getDoorLayoutInfo(layout, conf).handlePosition)) {
                return true;
            } else if (!blockedSashLayoutVariants.length) {
                const info = this.getDoorLayoutInfo(layout, conf);
                if (info) {
                    return conf.System.door_type
                        ? info.sideLR === sideLR &&
                              info.sideWZ === sideWZ &&
                              info.hasLeftLight === conf.OwnedSashesTypes?.doorRightLight &&
                              info.hasRightLight === conf.OwnedSashesTypes?.doorLeftLight &&
                              info.hasTopLight === conf.OwnedSashesTypes?.doorTopLight
                        : info.sideLR === sideLR && info && info.sideWZ === sideWZ;
                }
            }
        });
        const defaultBaseLayout = matchingLayouts.find(layout => {
            const info = this.getDoorLayoutInfo(layout, conf);
            const matched = info
                && !info.hasLeftLight
                && !info.hasTopLight
                && !info.hasRightLight
                && !info.hasPassiveSash;
            if (blockedSashLayoutVariants.length && !this.isSashLayoutBlocked(layout, blockedSashLayoutVariants, this.getDoorLayoutInfo(layout, conf).handlePosition)) {
                return matched;
            }
            return matched
                    && info.sideLR === sideLR
                    && info.sideWZ === sideWZ;
        });
        const defaultLayout = sameSideLayouts.find(layout => {
            const info = this.getDoorLayoutInfo(layout, conf);
            return currentLayoutInfo
                    && info
                    && currentLayoutInfo.hasLeftLight === info.hasLeftLight
                    && currentLayoutInfo.hasTopLight === info.hasTopLight
                    && currentLayoutInfo.hasRightLight === info.hasRightLight
                    && currentLayoutInfo.hasPassiveSash === info.hasPassiveSash;
        });

        let layouts = [];
        if (blockedSashLayoutVariants.length) {
            layouts = this.defaultLayouts.filter((layout) =>
                this.isSashLayoutBlocked(layout, blockedSashLayoutVariants, this.getDoorLayoutInfo(layout, conf).handlePosition) ? false : layout
            )
        }
        return defaultLayout
            || defaultBaseLayout
            || sameSideLayouts.length > 0 && sameSideLayouts[0]
            || (matchingLayouts.length ? matchingLayouts[0] : this.defaultLayouts[0]);
    }

    getSelectedLayout(conf = this.configurationsService.conf.Current, oldLayout?) {
        const layouts = this.getLayouts(conf);
        const selectedLayout = oldLayout;
        if (!selectedLayout && conf.Layout?.id) {
            return layouts.find(l => l.SashesLayoutsVariant?.id == conf.Layout.id);
        }
        return selectedLayout && layouts.find(l => l.SashesLayoutsVariant?.id == selectedLayout.SashesLayoutsVariant?.id);
    }

    getLayoutById(id, conf = this.configurationsService.conf.Current){
        const layouts = this.getLayouts(conf);
        return layouts.find(l => l.SashesLayoutsVariant.id == id) || {};
    }

    getDoorLayoutInfo(layout: any, conf = this.configurationsService.conf.Current) {
        if (layout) {
            let hasLeftLight = false;
            let hasRightLight = false;
            let hasTopLight = false;
            let hasPassiveSash = false;
            let hasActiveSash = false;
            let handlePosition = null;
            let sideLR = 'R';
            let sideWZ = 'W';
            const layoutNeighbours = (layout.Neighbours && layout.Neighbours.middle) ? true : false
            const sashes = layoutNeighbours ? layout.Neighbours.middle : layout.Sashes;

            hasTopLight = layoutNeighbours
                            ? isArray(layout.Neighbours.top) && layout.Neighbours.top.length > 0
                            : (layout.SashesLayout && layout.SashesLayout.layout_code && layout.SashesLayout.layout_code.division === '_')

            let minSashPosition = Number(sashes[0].position);
            let maxSashPosition = Number(sashes[0].position);
            sashes.forEach(s => {
                if (s.position < minSashPosition) minSashPosition = Number(s.position);
                if (s.position > maxSashPosition) maxSashPosition = Number(s.position);
            })

            if (!layoutNeighbours && hasTopLight) {
                sashes.filter(s => s.position !== minSashPosition);
                minSashPosition++;
            }


            if (sashes) {
                sashes.forEach(sash => {
                    if (['DRA', 'DOA'].includes(sash.type)) {
                        hasActiveSash = true;
                    }
                    if (['DRP', 'DOP'].includes(sash.type)) {
                        hasPassiveSash = true;
                    }

                    if (sash.type === 'DOA') {
                        sideWZ = 'Z';
                    }

                    if (
                        sash.type === 'DOA' && sash.handle_position === 'L'
                        || sash.type === 'DRA' && sash.handle_position === 'R'
                    ) {
                        sideLR = 'L';
                    }

                    if (sash.type === 'F') {
                        if (sash.position == minSashPosition) hasRightLight = true;
                        if (sash.position == maxSashPosition) hasLeftLight = true;
                    }
                });
            }

            if ((sideLR === "L" && sideWZ === "Z") || (sideLR === "R" && sideWZ === "W")) {
                handlePosition = "L";
            } else if ((sideLR === "R" && sideWZ === "Z") || sideLR === "L" && sideWZ === "W"){
                handlePosition = "R";
            }

            return {
                hasLeftLight,
                hasRightLight,
                hasTopLight,
                hasPassiveSash,
                hasActiveSash,
                sideLR,
                sideWZ,
                handlePosition
            }
        }
    }

    getDoorSides(conf = this.configurationsService.conf.Current) {
        let doorSideWZ: 'W' | 'Z' | null = null;
        let doorSideLR: 'L' | 'R' | null = null;
        if (conf.Sashes.length) {
            doorSideWZ = conf.Sashes.some(
                s => s.type && s.type.type === 'DOA'
            )
                ? 'Z'
                : 'W';
            doorSideLR = conf.Sashes.some(
                s =>
                    s.type
                    && (
                        s.type.type === 'DRA'
                            && s.type.handle_position === 'R'
                        || s.type.type === 'DOA' && s.type.handle_position === 'L'
                    )
            )
                ? 'L'
                : 'R';
        }
        return {doorSideWZ, doorSideLR};
    }

    getLayouts(conf: WindowActiveConfiguration, shape?: string, variant?: string) {
        shape = shape || conf.Shape?.shape || 'rect';
        variant = variant || conf.Shape?.type || null;
        return this.defaultLayouts
            .filter(
                layout =>
                    layout.SashesLayout.type === conf.System?.confType
                    && layout.SashesLayout.shape_id === shape
                    && layout.SashesLayoutsVariant.config
            )
            .filter(el => {
                if (
                    isObject(conf.System)
                    && isDefined(conf.System.id)
                    && isArray(el.SashesLayoutsVariant.not_systems)
                ) {
                    return el.SashesLayoutsVariant.not_systems.indexOf(conf.System.id) === -1;
                }
                return true;
            })
            .filter(el => {
                if (shape === 'rect' || shape === 'circle') {
                    return true;
                }
                return (
                    el.SashesLayoutsVariant.shape !== null
                    && el.SashesLayoutsVariant.shape.type === variant
                );
            })
            .filter(el => {
                if (conf.System?.confType === 'window' && conf.type === 'sliding_door') {
                    return el.Neighbours && (
                        el.Neighbours.middle.some(n => n.type === 'PSK')
                        || el.Neighbours.bottom.some(n => n.type === 'PSK')
                        || el.Neighbours.top.some(n => n.type === 'PSK')
                    ) || el.Sashes && el.Sashes.some(n => n.type === 'PSK');
                }
                return true;
            });
    }

    getBlockedSashLayoutVariants() {
        const blockedSashLayoutVariant: { types: any; options: any; }[] = [];
        const blockedItems = this.configurationsService.conf.Current.Dependencies.filter(
            (p) => p.type === 'blockade_to_configuration'
        );

        if (!blockedItems.length || !this.dependencies.length) {
            return blockedSashLayoutVariant;
        }

        this.dependencies.forEach((dependency) => {
            blockedItems.forEach(blocked => {
                if (dependency && blocked && dependency.id === Number(String(blocked.id).split('-')[0])) {
                    dependency.conditions.forEach((condition) => {
                        if (condition.type === 'sashLayoutVariant' && dependency.blockedSashLayoutVariant) {
                            blockedSashLayoutVariant.push(
                                {
                                    types: condition.value.sashesLayoutsVariantsTypes,
                                    options: condition.value?.sashesLayoutsVariantsOptions
                                }
                            )
                        }
                    });
                }
            })
        })

        return blockedSashLayoutVariant;
    }

    isSashLayoutBlocked(doorLayout, blockedSashLayoutVariant: { types: any; options: any; }[], handlePosition) {
        const doorLayoutInfo = this.getDoorLayoutInfo(doorLayout);
        let isSashLayoutBlocked = false;
        if (blockedSashLayoutVariant.length) {
            blockedSashLayoutVariant.length && blockedSashLayoutVariant.forEach((layout) => {
                layout.types.forEach((t: { id: number | 'ALL_SASHES_LAYOUTS_VARIANTS'; }) => {
                    if (layout.options?.includes('from_hinge_side') && t.id === 'ALL_SASHES_LAYOUTS_VARIANTS') {
                        switch (handlePosition) {
                            case 'L': {
                                isSashLayoutBlocked = doorLayoutInfo?.hasLeftLight || false;
                                break;
                            }
                            case 'R': {
                                isSashLayoutBlocked = doorLayoutInfo?.hasRightLight || false;
                                break;
                            }
                            default: false;
                        }
                    } else if (t.id === doorLayout.SashesLayoutsVariant.id ||
                        (t.id === 'ALL_SASHES_LAYOUTS_VARIANTS' ?
                            (doorLayoutInfo?.hasLeftLight || doorLayoutInfo?.hasRightLight || doorLayoutInfo?.hasTopLight) : false)) {
                        isSashLayoutBlocked = true;
                    }
                });
            });
        }
        return isSashLayoutBlocked;
    }

    isDoorSideAvailable(layoutSides) {
        const availableLayouts = this.getLayouts(this.configurationsService.conf.Current);
        const {arrWL, arrWR, arrZL, arrZR} = layoutSides;

        availableLayouts.forEach(layout => {
            if (layout.SashesLayoutsVariant.side === 'W') {
                (layout.Neighbours
                && layout.Neighbours.middle.some(
                    s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                ) || layout.Sashes
                && layout.Sashes.some(
                    s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                ))
                ? arrWL.push(layout)
                : arrWR.push(layout);
            }

            if (layout.SashesLayoutsVariant.side === 'Z') {
                (layout.Neighbours
                && layout.Neighbours.middle.some(
                    s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                ) || layout.Sashes
                && layout.Sashes.some(
                    s => s.type === 'DRA' && s.handle_position === 'R' || s.type === 'DOA' && s.handle_position === 'L'
                ))
                ? arrZL.push(layout)
                : arrZR.push(layout);
            }
        })
    }

    /**
     * Funkcja sprawdzająca czy w aktualnie wybranym układzie wszystkie pakiety szybowe są takie same
     */
    isGlazingSame() {
        const currGlazings = [];
        const allEqual = arr => arr.every( v => v.glazing.id === arr[0].glazing.id )

        this.configurationsService.conf.Current.Sashes.forEach(sash => {
            if (sash.type?.type === "F") {
                currGlazings.push(sash);
            }
        })

        if (allEqual(currGlazings)) {
            return currGlazings[0]?.glazing;
        }

        return null;
    }

    getLayoutRestrictionMessage(windowPrices: any, systemId: number, width: number, height: number): {dimensionMessage: string, systemMessage: string, show: boolean} {
        const windowLines = this.configuratorsDataService.data.windowLines?.map(line => ({id: line.id, name: line.name})) || [];
        const windowLinesId = windowLines.map((line: any) => Number(line.id));
        const pricesBySystem = windowPrices.filter((p: any) =>
                p.systems_profile_sets.some((a: any) =>
                    Number(a.window_line_id) === Number(systemId)
                )
            )
            .map((p: any) => p.data);

        const hasPrice = pricesBySystem.flat()
            .filter((a: any) =>
                Number(a.height_from) <= height
                && Number(a.height_to) >= height
                && Number(a.width_from) <= width
                && Number(a.width_to) >= width
            );

        const hasPriceInAnotherSystem = windowPrices.filter((p: any) =>
                p.systems_profile_sets.some((a: any) =>
                    windowLinesId.includes(Number(a.window_line_id))
                )
            )
            .map((p: any) => p.data)
            .flat()
            .filter((a: any) =>
                Number(a.height_from) <= height
                && Number(a.height_to) >= height
                && Number(a.width_from) <= width
                && Number(a.width_to) >= width
            );

        let systemMessage = '';
        if(hasPrice.length > 0) {
            return {dimensionMessage: '', systemMessage, show: false};
        }

        const dimensionMessage = this.dimensionsService.getDimensionMessageBaseOnPrice(pricesBySystem, width, height);
        if(hasPriceInAnotherSystem.length > 0) {
            const windowPricesId = hasPriceInAnotherSystem[0].window_price_id;
            const optionalSystemId = windowPrices.filter((p: any) => p.id === windowPricesId)[0]
                .systems_profile_sets.filter((p: any) =>  windowLinesId.includes(Number(p.window_line_id)))[0].window_line_id;
            const systemName = this.configuratorsDataService.data.windowLines?.filter((a: any) => Number(a.id) === Number(optionalSystemId))[0].name;
            systemMessage = this.translateService.instant("WINDOW|Układ jest dostępny w wybranym wymiarze m.in w systemie {systemName}", {systemName}) + " ";
        }


        return {dimensionMessage, systemMessage, show: true};
    }

    isShapeLayoutAvailable(conf: WindowActiveConfiguration, shape: string, variant?: string) {
        return this.getLayouts(conf, shape, variant).length > 0;
    }
}
