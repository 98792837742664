/* eslint-disable max-statements */
import { Injectable, Inject } from '@angular/core';
import { core, logger } from '@icc/common/helpers';
import { EventBusService } from '@icc/common/event-bus.service';
import { StepFactory } from '@icc/legacy/configurator/steps/StepFactory';
import { ConfiguratorsDataService, LoadedConfiguratorsDataValue } from '@icc/common/configurators/configurators-data.service';
import { WindowActiveConfiguration } from '@icc/common/configurations/WindowActiveConfiguration';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { Common } from '@icc/common/Common';
import { GlazingBeadsService } from '@icc/common/glazing-beads.service';
import {
    IssuesService,
    IssueLevel,
    InfoService,
    ModalService,
    StepsService,
    isArray,
    isObject
} from '@icc/helpers';
import { ValidationService } from '@icc/common/configurators/validation.service';
import { BondedGlazingService } from './bonded-glazing.service';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { APP_CONFIG, AppConfigFactory } from '@icc/common/config';
import { TranslateService } from '@icc/common/translate.service';
import { GlazingSizesService } from '@icc/common/configurators/glazing-sizes.service';
import { ConfigurationsService, ProfilesService, UserService } from '@icc/common';
import { FillingsListPageComponent } from 'libs/configurator/window/src/lib/fillings-list-page/fillings-list-page.component';
import { DecoPanelOptionsPageComponent } from 'libs/configurator/window/src/lib/deco-panel-options-page/deco-panel-options-page.component';
import { PanelGlazingsListPageComponent } from 'libs/configurator/window/src/lib/panel-glazings-list-page/panel-glazings-list-page.component';
import { IccFilling, IccGlassType, IccGlassTypeVariant } from '@icc/common/data-types';
import { BehaviorSubject } from 'rxjs';
import { GlazingUnitsService } from './glazing-units.service';
import { GlazingUnitElement } from '@icc/window';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { ModelListPageComponent } from 'libs/configurator/door/src/lib/model-list-page/model-list-page.component';
import { ColorRestrictionService } from '@icc/common/colors/colors-restriction.service';
import { NewColorsService } from 'libs/configurator/window/src/lib/colors/new-colors.service';


@Injectable()
export class FillingsService {
    fillings: IccFilling[] = [];
    fillingsCategories = [];
    glassTypes: IccGlassType[] = [];
    glassTypeVariants: IccGlassTypeVariant[] = [];
    modalGlassTypeData = {
        glassTab: 'glazing',
        selectedSecurity: null,
        selectedOrnament: null,
        selectedUg: null,
        selectedRw: null,
        selectedCategory: null,
    };
    hasGlasses = false;
    private loadedData = false;
    private allFillings: IccFilling[] = [];
    private decoPanelRestrictiveDimensions = {
        minWidth: 0,
        maxWidth: Number.POSITIVE_INFINITY,
        minHeight: 0,
        maxHeight: Number.POSITIVE_INFINITY,
    };

    constructor(
        private userService: UserService,
        private eventBusService: EventBusService,
        private infoFactory: InfoService,
        private modalService: ModalService,
        private glazingBeadsService: GlazingBeadsService,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private bondedGlazingService: BondedGlazingService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private translateService: TranslateService,
        private glazingSizesService: GlazingSizesService,
        private glazingUnitsService: GlazingUnitsService,
        private configurationsService: ConfigurationsService<"window">,
        private configuratorsDataService: ConfiguratorsDataService,
        private profilesService: ProfilesService,
        private stepsService: StepsService,
        private newColorsService: NewColorsService
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadFillings(data.value);
                this.decoPanelRestrictiveDimensions = data.value.panelsSettings;
                this.loadedData = true;
                this.hasGlasses = !!(
                    data.value.glasses
                    && data.value.glasses.length
                    && data.value.interPaneSpaces
                    && data.value.interPaneSpaces.length
                );
                if (data.activeConfiguration) {
                    this.loadMatchingFillings(
                        data.activeConfiguration as WindowActiveConfiguration
                    );
                }
            }
        );

        this.eventBusService.subscribe('*', data =>
            this.glazingSizesService.count(data.activeConfiguration)
        );

        this.eventBusService.subscribe('setSystem', data => {
            try {
                this.loadMatchingFillings(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setConstructionColor', data => {
            try {
                if (
                    WindowActiveConfiguration.is(data.activeConfiguration)
                    && (data.activeConfiguration.type !== 'door'
                        || !data.activeConfiguration.System.door_type)
                ) {
                    this.loadMatchingFillings(data.activeConfiguration);
                }
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedGlazingBeads', data => {
            try {
                this.loadMatchingFillings(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedFillings', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validatePanelGlazingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                if(data.activeConfiguration.type === 'door') {
                    this.updateStepLights(data.activeConfiguration as WindowActiveConfiguration);
                }
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('changedSashes', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    true
                );
                this.updateStepLights(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('changedFillings', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    data.value ? false : true
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<any>('setFrameProfile', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.updateStepLights(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setSashProfile', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.updateStepLights(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setMullionProfile', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.updateStepLights(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<any>('validatedProfiles', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validatePanelGlazingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration
                );
                if(data.activeConfiguration.type === 'door') {
                    this.updateStepLights(data.activeConfiguration as WindowActiveConfiguration);
                    // this.validateFillingColors(data.activeConfiguration as WindowActiveConfiguration, 'activeDoorSash');
                }
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setGlazingInSash', data => {
            try {
                this.checkSingleFilling(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateFillings(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setPanelGlazing', data => {
            try {
                this.checkSingleFilling(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setFillingColor', data => {
            try {
                this.checkSingleFillingColors(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
    }

    setFillingInAllSashes(conf: WindowActiveConfiguration, newFilling, isDefault = false) {
        const pauseId = this.eventBusService.pause(['setFillingColor', 'setGlazingInSash']);
        conf.Sashes.forEach(sash => {
            this.setFillingInSash(sash, sash, newFilling, conf, isDefault);
            sash.intSashes.forEach(field => {
                this.setFillingInSash(field, sash, newFilling, conf, isDefault);
            });
        });
        this.eventBusService.resume(['setFillingColor', 'setGlazingInSash'], pauseId);
    }

    setFillingInFilteredSashes(
        conf: WindowActiveConfiguration,
        newFilling,
        isDefault = false,
        filter: (sash) => boolean = () => true,
        defaultConf?,
        door?: 'doorActive' | 'doorActiveInner',
        changeLightsGlazing = false
    ) {
        const setGlazingModel = newFilling.type === 'deco_panel_glazing';
        const pauseId = this.eventBusService.pause(['setFillingColor', 'setGlazingInSash']);
        conf.Sashes.filter(filter).forEach(sash => {
            if (setGlazingModel && defaultConf) {
                newFilling = this.getDefaultFillingInSash(sash, sash, conf, defaultConf);
            }
            if(!changeLightsGlazing || (changeLightsGlazing && !sash.glazing.glazing_to_filter)) {
                this.setFillingInSash(sash, sash, newFilling, conf, isDefault, door);
            }
            sash.intSashes.forEach(field => {
                if (setGlazingModel && defaultConf) {
                    newFilling = this.getDefaultFillingInSash(sash, sash, conf, defaultConf);
                }
                this.setFillingInSash(field, sash, newFilling, conf, isDefault);
            });
        });
        this.eventBusService.resume(['setFillingColor', 'setGlazingInSash'], pauseId);
    }

    setFillingInSash(field, sash, newFilling, conf, isDefault = false, door?: 'doorActive' | 'doorActiveInner') {
        if (!newFilling || !newFilling.id) {
            return;
        }
        if(newFilling?.selected || !field.defaultGlazing ) {
            delete newFilling.selected;
            field.defaultGlazing = newFilling;

        }
        newFilling = core.copy(newFilling);
        newFilling.isDefault = isDefault;
        field.glazing = newFilling;

        if (
            field.id === sash.id
            && field.intSashes.length === 1
            && field.intSashes[0].glazing.id !== newFilling.id
        ) {
            this.setFillingInSash(field.intSashes[0], sash, newFilling, conf, isDefault);
        }
        if (field.id !== sash.id && sash.glazing.id !== newFilling.id) {
            this.setFillingInSash(sash, sash, newFilling, conf, isDefault);
        }
        this.setDecoPanelDefaults(field, sash, conf, door);
        this.eventBusService.post({
            key: 'setGlazingInSash',
            value: {
                glazingId: newFilling.id,
                sashId: sash,
            },
            conf,
        });
    }

    setDefaultFillingInSash(field, sash, conf: WindowActiveConfiguration, defaultConf) {
        const defaultFilling = this.getDefaultFillingInSash(field, sash, conf, defaultConf);
        this.setFillingInSash(field, sash, defaultFilling, conf, true);
        this.eventBusService.post({
            key: 'changedFillings',
            value: {},
        });
    }

    getDefaultFillingInSash(field, sash, conf: WindowActiveConfiguration, defaultConf) {
        const matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
        const sameGlazingDifferentThickness =
            sash.glazing
            && sash.glazing.id
            && matchingFillings.find(
                o => Number(o.id.split('.')[0]) === Number(sash.glazing.id.split('.')[0])
            );
        const defaultGlazingForSystem = matchingFillings.find(
            o => {
                if (conf.System.default_market_configuration?.glass_type_id) {
                    return Number(o.id.split('.')[0]) === Number(conf.System.default_market_configuration.glass_type_id);
                } else {
                    return Number(o.id.split('.')[0]) === Number(conf.System.default_glass_type_id);
                }
            }
        );

        const frame = conf.Frames.find(f => f.id === sash.frameId);
        const notDoorSash = (s) => ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(s.type.type) === -1;
        const isDoorLight = (s) => conf.type === 'door' && notDoorSash(s);
        const isSideLight = (s) => isDoorLight(s) && frame.y + s.ry + s.rHeight === conf.Height;
        const isTopLight = (s) => isDoorLight(s) && frame.y + s.ry + s.rHeight < conf.Height;
        let configSashFillings;
        if(isSideLight(sash)) {
            configSashFillings = frame.x === 0 ? conf.OneFilling.doorLeftLight : conf.OneFilling.doorRightLight;
            const activeSash = conf.Sashes.find(s => ['DRA', 'DOA'].indexOf(s.type.type) !== -1);
            if(activeSash.glazing?.glazing_ids?.length > 0 && configSashFillings) {
                configSashFillings = activeSash.glazing.default_glazing_id ?? activeSash.glazing.glazing_ids[0];
                configSashFillings = matchingFillings.find(f => Number(f.id.split('.')[0]) === Number(configSashFillings))?.id;
            } else {
                const currentSideLight = matchingFillings.find(f => f.id === configSashFillings);
                if(configSashFillings && currentSideLight?.glazing_to_filter) {
                    configSashFillings = matchingFillings.filter(f => !f.glazing_to_filter)[0].id;
                }
            }
        } else if(isTopLight(sash)){
            configSashFillings = conf.OneFilling.doorTopLight;
        }
        if(configSashFillings) {
            configSashFillings = matchingFillings.find(f => f.id === configSashFillings);
        }

        const defaultDecoPanel = matchingFillings.find(
            o => DoorActiveConfiguration.is(conf) && conf.Model && o.id === conf.Model.id
        );
        const defaultDecoPanelPassive = matchingFillings.find(
            o =>
                DoorActiveConfiguration.is(conf)
                && conf.ModelOptions.passiveSashModel
                && Number(o.id.split('.')[0])
                    === Number(conf.ModelOptions.passiveSashModel.id.split('.')[0])
        );
        let defaultGlazing;
        let filling;
        if(this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
            const thermalTransmittanceFilling = this.getFillingBaseOnMappingThermalTransmittance(field, sash, conf);
            const thicknessFilling = this.getFillingBaseOnMappingThickness(field, conf, thermalTransmittanceFilling);
            if(thicknessFilling) {
                return thicknessFilling;
            }
        }
        if (configSashFillings) {
            filling = configSashFillings;
        }
        if (!filling && defaultDecoPanelPassive && ['DRP', 'DOP'].indexOf(sash.type.type) > -1) {
            filling = core.copy(defaultDecoPanelPassive);
        }
        if (
            !filling
            && defaultDecoPanel
            && ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) > -1
        ) {
            filling = core.copy(defaultDecoPanel);
        }

        if (!filling && sash.type.type === 'F' && this.isDoorPanel(field.glazing)) {
            filling = core.copy(defaultGlazingForSystem);
        }
        if (!filling && sameGlazingDifferentThickness) {
            filling = core.copy(sameGlazingDifferentThickness);
        }

        if (!filling && defaultConf.OneGlazing && defaultConf.Glazing && defaultConf.Glazing.id) {
            defaultGlazing = core.fIdO(matchingFillings, defaultConf.Glazing.id);
        }
        if (!filling && !defaultGlazing && defaultConf.OneGlazing && defaultConf.OneFilling && (defaultConf.OneFilling.window || defaultConf.OneFilling.doorActive)) {
            defaultGlazing = core.fIdO(matchingFillings, defaultConf.OneFilling.window || defaultConf.OneFilling.doorActive);
        }
        if (!filling && defaultGlazing) {
            filling = core.copy(defaultGlazing);
        }
        if (!filling && defaultGlazingForSystem) {
            filling = core.copy(defaultGlazingForSystem);
        }
        if (!filling) {
            filling = core.copy(matchingFillings[0]);
        }

        if (
            filling
            && field.id !== sash.id
            && sash.glazing.id === filling.id
            && sash.glazing.selectedColor
        ) {
            filling.selectedColor = core.copy(sash.glazing.selectedColor);
        }

        return filling;
    }

    setDecoPanelDefaults(field, sash, conf, doorSide?: 'doorActive' | 'doorActiveInner') {
        if (!this.isDoorPanel(sash.glazing)) {
            sash.panelGlazing = {};
            sash.panelInner = null;
        } else {
            if (field.glazing.type === 'door_panels' || !this.isValidPanelType(sash)) {
                this.setDefaultPanelTypeInSash(sash);
            }
            if (!this.isValidPanelGlazing(sash)) {
                this.setDefaultPanelGlazingInSash(sash);
            }
            if (!this.isValidInnerPanel(sash, conf, doorSide)) {
                this.setDefaultInnerPanelInSash(sash, sash, conf, doorSide);
            }
        }
        if (sash.glazing.type !== 'glazing' && sash.glazing.type !== 'panels_glazing') {
            this.validateFillingColors(conf, sash);
        }
    }

    setDefaultModelOptions(conf: DoorActiveConfiguration) {
        if (conf.Model) {
            const matchingPanelTypes = this.getMatchingPanelTypesForModel(conf);
            conf.ModelOptions.panelType = this.getMatchingPanelType(matchingPanelTypes);
            if (!this.isValidPanelGlazingForModel(conf)) {
                conf.ModelOptions.panelGlazing = this.getMatchingPanelGlazingsForModel(conf)[0];
                conf.ModelOptions.passivePanelGlazing = this.getMatchingPanelGlazingsForDoorSashes(conf, false)[0];
            }
            if (!this.isValidInnerPanelForModel(conf)) {
                if (conf.System?.door_type) {
                    const panels = this.getPanelsBasedOnPanelBaseSide(conf);
                    conf.ModelOptions.panelInner = core.copy(panels[0]);
                } else {
                    conf.ModelOptions.panelInner = core.copy(this.getMatchingInnerPanelsForModel(conf)[0]);
                }
            }
        }
    }

    setPanelGlazingInSash(sash, panelGlazing) {
        sash.panelGlazing = core.copy(panelGlazing);
        this.eventBusService.post({
            key: 'setPanelGlazing',
            value: null,
        });
    }

    setInnerPanelInFilteredSashes(
        conf: DoorActiveConfiguration,
        newFilling,
        isDefault = false,
        filter: (sash) => boolean = () => true,
        defaultConf?,
        doorSide?: 'doorActiveInner' | 'doorActive'
    ) {
        conf.Sashes.filter(filter).forEach(sash => {
            this.setInnerPanelInSash(sash, sash, newFilling, conf, isDefault, doorSide);
            sash.intSashes.forEach(field => {
                this.setInnerPanelInSash(field, sash, newFilling, conf, isDefault);
            });
        });
    }

    setInnerPanelInSash(
        field,
        sash: ActiveSash,
        newFilling,
        conf: DoorActiveConfiguration,
        isDefault = false,
        doorSide?: 'doorActiveInner' | 'doorActive'
    ) {
        if (!newFilling || !newFilling.id) {
            return;
        }
        newFilling = core.copy(newFilling);
        newFilling.isDefault = isDefault;
        field.panelInner = newFilling;
        if (
            field.id === sash.id
            && field.intSashes.length === 1
            && (!field.intSashes[0].panelInner
                || field.intSashes[0].panelInner.id !== newFilling.id)
        ) {
            this.setInnerPanelInSash(field.intSashes[0], sash, newFilling, conf, isDefault, doorSide);
        }
        if (field.id !== sash.id && (!sash.panelInner || sash.panelInner.id !== newFilling.id)) {
            this.setInnerPanelInSash(sash, sash, newFilling, conf, isDefault);
        }

        this.setDefaultPanelTypeInSash(sash);
        this.setDecoPanelDefaults(field, sash, conf, doorSide);

        this.eventBusService.post({
            key: 'setGlazingInSash',
            value: {
                glazingId: newFilling.id,
                sashId: sash,
            },
            conf,
        });
    }

    setDefaultPanelGlazingInSash(sash) {
        const defaultPanelGlazing = this.getDefaultPanelGlazing(sash);
        this.setPanelGlazingInSash(sash, defaultPanelGlazing);
    }

    setDefaultInnerPanelInSash(field, sash, conf: DoorActiveConfiguration, doorSide: 'doorActive' | 'doorActiveInner') {
        let matchingFillings = this.getMatchingInnerPanelsForSash(field, sash, conf);
        if (matchingFillings.length === 0) {
            field.panelInner = null;
            sash.panelInner = null;
            return;
        }

        if (conf.System.door_type && doorSide === 'doorActive') {
            const outerFillingId = sash?.glazing?.id;
            if (sash?.glazing?.base_side === 'inside') {
                matchingFillings = matchingFillings.filter(
                    (p) => p.base_side === 'inside' && Number(p.id) === Number(outerFillingId)
                );
            } else {
                matchingFillings = matchingFillings.filter(
                    (p) =>
                        (p.base_side === 'outside' && Number(p.id) === Number(outerFillingId)) ||
                        p.base_side === 'any'
                );
            }
        }

        const defaultPanelForSystem = matchingFillings.find(
            o => {
                if (conf.System.default_market_configuration?.glass_type_id) {
                    return Number(o.id.split('.')[0]) === Number(conf.System.default_market_configuration.glass_type_id);
                } else {
                    return Number(o.id.split('.')[0]) === Number(conf.System.default_glass_type_id);
                }
            }
        );


        let filling;
        if (!filling && defaultPanelForSystem) {
            filling = core.copy(defaultPanelForSystem);
        }
        if (!filling) {
            filling = core.copy(matchingFillings[0]);
        }

        if (
            filling
            && field.id !== sash.id
            && sash.panelInner.id === filling.id
            && sash.panelInner.selectedColor
        ) {
            filling.selectedColor = core.copy(sash.panelInner.selectedColor);
        }
        this.setInnerPanelInSash(field, sash, filling, conf, true);
    }

    setPanelTypeInSash(sash, panelType) {
        sash.panelType = panelType;
        if (sash.intSashes) {
            sash.intSashes.forEach(field => {
                this.setPanelTypeInSash(field, panelType);
            });
        }
    }

    getPanelsBasedOnPanelBaseSide(conf) {
        const sash = Number(
            conf.Sashes.find((s) => ['DRA', 'DOA'].includes(s.type.type))?.glazing?.id
        );
        return this.getMatchingInnerPanelsForModel(conf).filter(
            (p) =>
                (p.base_side === 'outside' && Number(p.id) === sash) ||
                p.base_side === 'any' ||
                p.base_side === 'inside'
        );
    }

    setDefaultPanelTypeInSash(sash) {
        const matchingPanelTypesForSash = this.getMatchingPanelTypesForSash(sash);
        const defaultPanelType = this.getMatchingPanelTypeForSash(sash, matchingPanelTypesForSash);
        this.setPanelTypeInSash(sash, defaultPanelType);
    }

    getDefaultPanelGlazing(sash) {
        const matchingPanelGlazings = this.getMatchingPanelGlazingsForSash(sash);
        return matchingPanelGlazings[0];
    }

    getDefaultPanelGlazingForModel(conf: DoorActiveConfiguration) {
        const matchingPanelGlazings = this.getMatchingPanelGlazingsForModel(conf);
        return matchingPanelGlazings[0];
    }

    getDefaultPanelInner(field, sash, conf: DoorActiveConfiguration) {
        const matchingInnerPanels = this.getMatchingInnerPanelsForSash(field, sash, conf);
        return matchingInnerPanels[0];
    }

    setPanelOptionsInAllSashes(conf: WindowActiveConfiguration, newGlazing, panelType) {
        const pauseId = this.eventBusService.pause(['setPanelGlazing']);
        conf.Sashes.forEach(sash => {
            this.setPanelGlazingInSash(sash, newGlazing);
            this.setPanelTypeInSash(sash, panelType);
            sash.intSashes.forEach(field => {
                this.setPanelGlazingInSash(field, newGlazing);
                this.setPanelTypeInSash(field, panelType);
            });
        });
        this.eventBusService.resume(['setPanelGlazing'], pauseId);
    }

    setPanelGlazingsInAllSashes(conf: WindowActiveConfiguration, newGlazing) {
        const pauseId = this.eventBusService.pause(['setPanelGlazing']);
        conf.Sashes.forEach(sash => {
            this.setPanelGlazingInSash(sash, newGlazing);
            sash.intSashes.forEach(field => {
                this.setPanelGlazingInSash(field, newGlazing);
            });
        });
        this.eventBusService.resume(['setPanelGlazing'], pauseId);
    }

    setDecorativePanelInDoorSashes(
        conf: DoorActiveConfiguration,
        defaultConf: any,
        filling: IccFilling,
        field = 'door',
        changeLightsGlazing = false
    ) {
        if (DoorActiveConfiguration.is(conf) && (field === 'door' || field === 'doorActive')) {
            conf.Model = filling;
            conf.ModelOptions.hasBeenChosen = true;
            this.setDefaultModelOptions(conf);
        }
        if (DoorActiveConfiguration.is(conf) && field === 'doorPassive') {
            conf.ModelOptions.passiveSashModel = filling;
        }
        if (field !== 'doorActiveInner') {
            this.setFillingInFilteredSashes(
                conf,
                filling,
                false,
                this.getFilterForField(field, conf),
                defaultConf,
                'doorActive',
                changeLightsGlazing
            );
        } else {
            this.setInnerPanelInFilteredSashes(
                conf,
                filling,
                false,
                this.getFilterForField(field, conf),
                defaultConf,
                field
            );
        }
        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }
        this.eventBusService.post({
            key: 'changedFillings',
            value: field === 'doorLight',
        });
        if (field !== 'doorLight') {
            const fillingsData = this.getPanelGlazingsByFieldType(field, conf);
            const sash = fillingsData.sash;
            if (field === 'doorActiveInner') {
                this.setDecoPanelDefaults(sash, sash, conf, field);
            } else {
                this.setDecoPanelDefaults(sash, sash, conf);
            }
        }
        if (field !== 'doorActiveInner' && field !== 'doorLight') {
            if (filling.type === 'door_panels') {
                return this.changePanelGlazing((field === 'door' || field === 'doorActive') ? 'doorActive' : (field === 'doorPassive' ? 'doorPassive' : 'door'), conf);
            } else {
                return this.openModalPanelOptions(field === 'door' ? 'doorActive' : 'door', conf);
            }
        } else {
            return Promise.resolve();
        }
    }

    loadFillings(data) {
        this.allFillings = core.copy(data.fillings) || [];
        this.fillingsCategories = core.copy(data.fillingsCategory) || [];
        this.glassTypes = isArray(data.glassTypes) ? core.copy(data.glassTypes) : [];
        this.glassTypeVariants = isArray(data.glassTypeVariants)
            ? core.copy(data.glassTypeVariants)
            : [];
    }

    async addGlazingUnit(filling = null) {
        let [newGlazingUnit, producerId] = await this.glazingUnitsService.openGlazingUnitModal(
            filling ? <GlazingUnitElement[]>core.copy(filling.glazing_unit) : null,
            filling ? Number(filling.fillings_producer_id) : null
        );
        if (newGlazingUnit) {
            const newGlazingUnitJson = JSON.stringify(newGlazingUnit);
            if (filling && JSON.stringify(filling.glazing_unit) == newGlazingUnitJson) {
                return false;
            } else {
                const matchedFillings = this.allFillings.filter(
                    el => el.glazing_unit && JSON.stringify(el.glazing_unit) == newGlazingUnitJson
                );

                if (matchedFillings.length) {
                    return matchedFillings[0];
                } else {
                    let newFilling = this.glazingUnitsService.newFillingFromGlazingUnit(
                        newGlazingUnit,
                        producerId,
                        filling
                    );
                    this.saveCustomFilling(newFilling);
                    this.allFillings.unshift(newFilling);
                    return newFilling;
                }
            }
        } else {
            return false;
        }
    }

    saveCustomFilling(filling) {
        this.fillings.unshift(filling);
        this.allFillings.unshift(filling);
        const customFillings = core.parseJson(localStorage.getItem('customFillings') || '[]');
        customFillings.push(filling);
        localStorage.setItem('customFillings', JSON.stringify(customFillings));
    }

    loadMatchingFillings(conf: WindowActiveConfiguration, validate = true) {
        this.validationService.indeterminate(conf, 'loadedFillings');
        if (this.validationService.isValidElements(conf, ['system', 'colors']) && this.loadedData) {
            if (conf.CustomFillings) {
                conf.CustomFillings = this.glazingUnitsService.fixGlazingUnits(conf.CustomFillings);
                conf.CustomFillings.forEach(customFilling => {
                    if (!this.allFillings.some(el => el.id === customFilling.id)) {
                        this.allFillings.unshift(customFilling);
                    }
                });
            }
            this.fillings = this.allFillings.filter(filling => {
                const systemTypes = filling.system_types;
                const systemIds = filling.system_ids;
                return (
                    (filling.conf.length > 0
                        || filling.type === 'panels_glazing'
                        || this.isDoorPanel(filling))
                    && (filling.type === 'panels_glazing'
                        && !this.config().IccConfig.Configurators.glazingPanelsAvailabilityDependingOnProductSystem
                        || (Common.isArray(systemTypes)
                        && Common.isArray(systemIds)
                        && systemIds.indexOf(conf.System.id) > -1
                        && systemTypes.indexOf(conf.System.type) > -1))
                );
            });
            if (validate) {
                if (this.fillings.length === 0) {
                    this.validationService.invalid(conf, 'loadedFillings');
                    this.issuesService.registerDataProblem(
                        'no-matching-fillings',
                        'Brak pasujących wypełnień',
                        conf,
                        {
                            level: IssueLevel.FATAL,
                            extra: {
                                systemId: conf.System.id,
                                systemName: conf.System.name,
                                systemType: conf.System.type,
                            },
                        }
                    );
                } else {
                    Object.assign(conf, this.validationService.valid(conf, 'loadedFillings'));
                    this.issuesService.unregister('no-matching-fillings', conf);
                }
                this.eventBusService.post({
                    key: 'loadedFillings',
                    value: this.fillings,
                    conf,
                });
            }
        }
    }

    setDefaultGlassForSystem(conf, defaultConf) {
        conf.Sashes.forEach((sash) => {
            this.setDefaultFillingInSash(sash, sash, conf, defaultConf);
            const defaultFilling = this.getDefaultGlazingForSystem(sash, sash, conf, defaultConf);
            this.setFillingInSash(sash, sash, defaultFilling, conf, true);

            sash.intSashes.forEach((field) => {
                this.setDefaultFillingInSash(field, sash, conf, defaultConf);
                const flng = this.getDefaultGlazingForSystem(field, sash, conf, defaultConf);
                this.setFillingInSash(field, sash, flng, conf, true);
            });
        });
    }

    getDefaultGlazingForSystem(field, sash, conf: WindowActiveConfiguration, defaultConf) {
        const matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
        const defaultGlazingForSystem = matchingFillings.find(
            o => {
                if (conf.System.default_market_configuration?.glass_type_id) {
                    return Number(o.id.split('.')[0]) === Number(conf.System.default_market_configuration.glass_type_id);
                } else {
                    return Number(o.id.split('.')[0]) === Number(conf.System.default_glass_type_id);
                }
            }
        );

        return defaultGlazingForSystem;
    }


    validateFillingsAndFixIssues(conf: WindowActiveConfiguration, defaultConf, validateSideLight = false) {
        this.validationService.indeterminate(conf, 'fillings');
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
        ) {
            conf.Issues = conf.Issues.filter(
                i => i.key.indexOf(`no-matching-fillings-in-sash`) === -1
            );
            this.issuesService.unregister('invalid-fillings', conf);
            const pauseId = this.eventBusService.pause(['setGlazingInSash', 'setFillingColor', 'setPanelGlazing']);
            let valid = true;
            try {
                conf.Sashes.forEach(sash => {
                    if (!this.validFillingInSash(sash, sash, conf, validateSideLight) || this.fillingMapAvailable(sash, sash,  conf)) {
                        this.setDefaultFillingInSash(sash, sash, conf, defaultConf);
                        valid = false;
                    }
                    sash.intSashes.forEach(field => {
                        if (!this.validFillingInSash(field, sash, conf) || this.fillingMapAvailable(field, sash,  conf)) {
                            this.setDefaultFillingInSash(field, sash, conf, defaultConf);
                            valid = false;
                        }
                    });
                });
                if (valid) {
                    this.validationService.valid(conf, 'fillings');
                    this.issuesService.unregister('invalid-fillings', conf);
                } else {
                    this.validationService.invalid(conf, 'fillings');
                }
            } finally {
                this.eventBusService.resume(['setGlazingInSash', 'setFillingColor', 'setPanelGlazing'], pauseId);
            }
        }
    }

    fillingMapAvailable(field, sash, conf) {
        if(!this.config().IccConfig.Configurators.roller_shutter.configuratorDimensionalRestriction) {
            return false;
        }
        const thermalTransmittanceFilling = this.getFillingBaseOnMappingThermalTransmittance(field, sash, conf);
        const thicknessFilling = this.getFillingBaseOnMappingThickness(field, conf, thermalTransmittanceFilling);
        const hasThermalTransmittanceFilling = thermalTransmittanceFilling ? thermalTransmittanceFilling.id !== field.glazing.id : false;
        const hasThicknessFilling = thicknessFilling ? thicknessFilling.id !== field.glazing.id : false;
        return hasThermalTransmittanceFilling || hasThicknessFilling;
    }

    getFillingBaseOnMappingThermalTransmittance(field, sash, conf) {
        const defaultGlazing = field.defaultGlazing;
        const hasMapping = defaultGlazing?.filling_maps?.thermal_transmittance?.length || false

        if(!defaultGlazing || !hasMapping) {
            return null
        }

        const thermalTransmittanceFillingsId = [defaultGlazing.id.split('.')[0], ...defaultGlazing.filling_maps.thermal_transmittance];

        const matchingFillingThicknessInSash = this.glazingBeadsService.getMatchingFillingThicknessInSash(field, sash, conf);
        for(const fillingId of thermalTransmittanceFillingsId) {
            const filling = this.fillings.find(f => Number(f.id.split('.')[0]) === Number(fillingId) && f.type === 'glazing');
            if(filling) {
                const match =  matchingFillingThicknessInSash.some(
                    range =>
                        Math.round(Number(filling.thickness_mm)) >= range.min
                        && Math.round(Number(filling.thickness_mm)) <= range.max);
                if(match) {
                    return filling
                }
            }
        }
        return null;
    }

    getFillingBaseOnMappingThickness(field, conf, thermalTransmittanceFilling) {
        const defaultGlazing = thermalTransmittanceFilling ?? field.defaultGlazing;
        const hasMapping = defaultGlazing?.filling_maps?.thickness?.length || false
        if((!defaultGlazing || !hasMapping) && !thermalTransmittanceFilling) {
            return null;
        }

        const thicknessFillingsId = [defaultGlazing.id.split('.')[0], ...defaultGlazing?.filling_maps?.thickness || []];

        const glazingRestricts = this.configuratorsDataService.data.glazingRestrictions;
        const fillingDrawData = conf.drawData
            && this.configurationsService.conf.Current.drawData.filling
            && this.configurationsService.conf.Current.drawData.filling.find(o => o.sashId === field.id);
        const glazingWidth = fillingDrawData ? fillingDrawData.rect.width : 0;
        const glazingHeight = fillingDrawData ? fillingDrawData.rect.height : 0;
        const glazingArea = fillingDrawData ? fillingDrawData.rectArea : 0;

        for(const fillingId of thicknessFillingsId) {
            const filling = this.fillings.find(f => Number(f.id.split('.')[0]) === Number(fillingId) && f.type === 'glazing');
            if(filling) {
                const match = this.checkGlazing(filling, glazingRestricts, glazingWidth, glazingHeight, glazingArea);
                if(match) {
                    return filling
                }
            }
        }
        return null;
    }



    validatePanelGlazingsAndFixIssues(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'panelGlazings');
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
        ) {
            const pauseId = this.eventBusService.pause(['setPanelGlazing']);
            let valid = true;
            try {
                conf.Sashes.forEach(sash => {
                    if (!this.isValidPanelGlazing(sash)) {
                        this.setDefaultPanelGlazingInSash(sash);
                        valid = false;
                    }
                    sash.intSashes.forEach(field => {
                        if (!this.isValidPanelGlazing(field)) {
                            this.setDefaultPanelGlazingInSash(field);
                            valid = false;
                        }
                    });
                });
                if (valid) {
                    this.validationService.valid(conf, 'panelGlazings');
                } else {
                    this.validationService.invalid(conf, 'panelGlazings');
                }
            } finally {
                this.eventBusService.resume(['setPanelGlazing'], pauseId);
            }
        }
    }

    validateFillings(conf: WindowActiveConfiguration, defaultConf) {
        this.validationService.indeterminate(conf, 'fillings');
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
        ) {
            const valid = conf.Sashes.every(
                sash =>
                    this.validFillingInSash(sash, sash, conf)
                    && sash.intSashes.every(field => this.validFillingInSash(field, sash, conf))
            );
            if (!valid) {
                this.validationService.invalid(conf, 'fillings');
                this.issuesService.simpleRegister(
                    'invalid-fillings',
                    'Niepoprawne wypełnienia!',
                    this.translateService.instant('WINDOW|Niepoprawne wypełnienia!'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.INFO,
                        blockStepsAfter: 'glazing',
                    }
                );
            } else {
                Object.assign(conf, this.validationService.valid(conf, 'fillings'));
                this.issuesService.unregister('invalid-fillings', conf);
            }
            this.eventBusService.post({
                key: 'validatedFillings',
                value: valid,
            });
        }
    }

    validFillingInSash(field, sash, conf: WindowActiveConfiguration, validateSideLight = false) {
        if (
            !sash.glazing
            || !sash.glazing.id
            || (sash.type.type === 'F' && this.isDoorPanel(field.glazing))
        ) {
            return false;
        }

        const filling = field.glazing;

        const matchingThickness = this.glazingBeadsService.getMatchingFillingThicknessInSash(
            field,
            sash,
            conf,
            filling.type === 'deco_panels' ? filling.panel_type : 'panel_type_inset'
        );

        const systemTypes = filling.system_types;
        const systemIds = filling.system_ids;
        const overlayThickness = this.getDecoPanelOverlayThickness(filling);

        const alwaysMatchProfile = this.isAlwaysMatchProfile(filling);

        const activeSash = conf.Sashes.find(s => ['DRA', 'DOA'].indexOf(s.type.type) !== -1);
        const glazingIds = activeSash?.glazing?.glazing_ids || [];
        const notDoorSash = conf.type !== 'door'
            || ((conf as DoorActiveConfiguration).Model
            && (conf as DoorActiveConfiguration).Model.standard_fills)
            || ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) === -1;
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        const isDoorLight = conf.type === 'door' && notDoorSash;
        const isSideLight = isDoorLight && frame.y + sash.ry + sash.rHeight === conf.Height;
        let sideLightCorrect = true;
        if(isSideLight && ((filling.glazing_to_filter && glazingIds.length === 0) || glazingIds.length > 0) && validateSideLight) {
            sideLightCorrect = false;
        }

        return (
            ((this.isDoorPanel(filling)
                && ((filling.type === 'deco_panels'
                    && (field.intSashes.length
                        || this.isDecoPanelMatchToDimensions(filling, field, sash, conf)
                        && (alwaysMatchProfile || matchingThickness.some(
                            range =>
                                Number(filling.thickness_mm) - overlayThickness >= range.min
                                && Number(filling.thickness_mm) - overlayThickness <= range.max
                        ))
                    ))
                    || (filling.type === 'door_panels'
                        && this.isDoorPanelMatchToDoorSizes(
                            filling,
                            sash,
                            conf as DoorActiveConfiguration
                        ))))
                || (!this.isDoorPanel(filling)
                    && filling.type !== 'panels_glazing'
                    && matchingThickness.some(
                        range =>
                            Math.round(Number(filling.thickness_mm)) >= range.min
                            && Math.round(Number(filling.thickness_mm)) <= range.max
                    )
                    && this.isFillingMatchToSashType(conf, sash, filling)
                    && sideLightCorrect))
            && Common.isArray(systemTypes)
            && Common.isArray(systemIds)
            && systemIds.indexOf(conf.System.id) > -1
            && systemTypes.indexOf(conf.System.type) > -1
        );
    }

    private getDecoPanelOverlayThickness(filling: any) {
        let overlayThickness = 0;
        if (filling.panel_type === 'panel_type_double') {
            overlayThickness = 2 * (filling.overlay_thickness || 0);
        }
        else if (filling.panel_type === 'panel_type_inner' || filling.panel_type === 'panel_type_outer') {
            overlayThickness = filling.overlay_thickness || 0;
        }
        return overlayThickness;
    }

    private isFillingMatchToSashType(conf: WindowActiveConfiguration, sash: ActiveSash, filling: IccFilling): any {
        const notDoorSash = conf.type !== 'door'
            || ((conf as DoorActiveConfiguration).Model
            && (conf as DoorActiveConfiguration).Model.standard_fills)
            || ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) === -1;
        const isDoorLight = conf.type === 'door' && notDoorSash;
        const frame = conf.Frames.find(f => f.id === sash.frameId);
        const isSideLight = isDoorLight && frame.y + sash.ry + sash.rHeight === conf.Height;
        const isTopLight = isDoorLight && frame.y + sash.ry + sash.rHeight < conf.Height;
        if(isSideLight) {
            const activeSash = conf.Sashes.find(s => ['DRA', 'DOA'].indexOf(s.type.type) !== -1);
            const glazingIds = activeSash?.glazing?.glazing_ids?.map(id => Number(id)) || [];
            if(filling.glazing_to_filter && !glazingIds.includes(Number(filling.id.toString().split('.')[0]))) {
                return false;
            }
        }
        return (
            (
                notDoorSash
                && filling.conf.indexOf('window') > -1
            ) || isSideLight && filling.conf.indexOf('side-light') > -1
            || isTopLight && filling.conf.indexOf('top-light') > -1
            || filling.conf.indexOf('door') > -1
        );
    }

    checkSingleFilling(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        defaultConf: any
    ) {
        let isSingleFilling = true;
        let isSingleFillingInWindowSashes = true;
        let isSingleFillingInDoorActiveSashes = true;
        let isSingleInnerPanelInDoorActiveSashes = true;
        let isSingleFillingInDoorPassiveSashes = true;
        let isSingleFillingInDoorLightSashes = true;
        let isSingleFillingInDoorTopLightSashes = true;
        let isSingleFillingInDoorLeftLightSashes = true;
        let isSingleFillingInDoorRightLightSashes = true;
        let firstFillingId: string | null = null;
        let firstFillingInWindowSashesId: string | null = null;
        let firstFillingInDoorActiveSashesId: string | null = null;
        let firstInnerPanelInDoorActiveSashesId: string | null = null;
        let firstFillingInDoorPassiveSashesId: string | null = null;
        let firstInnerFillingInDoorPassiveSashesId: string | null = null;
        let firstFillingInDoorLightSashesId: string | null = null;
        let firstFillingInDoorTopLightSashesId: string | null = null;
        let firstFillingInDoorLeftLightSashesId: string | null = null;
        let firstFillingInDoorRightLightSashesId: string | null = null;
        let firstPanelType: 'Inset' | 'Outer' | 'Double' | 'Inner' | 'Mixed' = 'Inset';
        let firstPanelGlazing: any = null;
        let firstPassivePanelGlazing: any = null;

        conf.Sashes.forEach((sash, i) => {
            let isSingleFillingInSash = true;
            let firstFillingIdInSash = sash.glazing.id || null;

            isSingleFillingInSash = sash.intSashes.every((intSash, j) => {
                if (j === 0) {
                    firstFillingIdInSash = intSash.glazing.id || null;
                }
                return firstFillingIdInSash === intSash.glazing.id;
            });

            if (i === 0) {
                firstFillingId = firstFillingIdInSash;
            }

            if (
                i === 1 &&
                conf.Sashes[0]?.panelGlazing?.id &&
                conf.Layout?.sashes.length > 1 &&
                conf.Layout?.sashes.every(s => s?.type === conf.Layout?.sashes[0]?.type)
            ) {
                sash.panelGlazing = conf.Sashes[0]?.panelGlazing;
            }

            if (sash.type.type === 'DRA' || sash.type.type === 'DOA') {
                if (!firstFillingInDoorActiveSashesId) {
                    firstFillingInDoorActiveSashesId = firstFillingIdInSash;
                }
                if (!firstInnerPanelInDoorActiveSashesId) {
                    firstInnerPanelInDoorActiveSashesId =
                        (sash.panelInner && sash.panelInner.id) || null;
                }
                isSingleFillingInDoorActiveSashes =
                    isSingleFillingInDoorActiveSashes
                    && isSingleFillingInSash
                    && firstFillingInDoorActiveSashesId === firstFillingIdInSash;

                isSingleInnerPanelInDoorActiveSashes =
                    isSingleInnerPanelInDoorActiveSashes
                    && (!sash.panelInner
                        || firstInnerPanelInDoorActiveSashesId === sash.panelInner.id);
                firstPanelType = sash.panelType;
                firstPanelGlazing = core.copy(sash.panelGlazing);
            } else if (sash.type.type === 'DRP' || sash.type.type === 'DOP') {
                if (!firstFillingInDoorPassiveSashesId) {
                    firstFillingInDoorPassiveSashesId = firstFillingIdInSash;
                }
                if (!firstInnerFillingInDoorPassiveSashesId) {
                    firstInnerFillingInDoorPassiveSashesId =
                        (sash.panelInner && sash.panelInner.id) || null;
                }
                isSingleFillingInDoorPassiveSashes = Boolean(
                    firstFillingInDoorPassiveSashesId
                        && isSingleFillingInSash
                        && firstFillingInDoorPassiveSashesId === firstFillingIdInSash
                );
                firstPassivePanelGlazing = core.copy(sash.panelGlazing);
            } else {
                if (!firstFillingInWindowSashesId) {
                    firstFillingInWindowSashesId = firstFillingIdInSash;
                }
                isSingleFillingInWindowSashes =
                    isSingleFillingInWindowSashes
                    && isSingleFillingInSash
                    && firstFillingInWindowSashesId === firstFillingIdInSash;
            }

            if (sash.type.type === 'F') {
                const frame = conf.Frames.find(f => f.id === sash.frameId);
                if (sash.nearMullions.left === -1 && ((frame && frame.x) || 0) + sash.rx === 0) {
                    if (!firstFillingInDoorLightSashesId) {
                        firstFillingInDoorLightSashesId = firstFillingIdInSash;
                    }
                    if (
                        sash.nearMullions.top === -1
                        && ((frame && frame.x) || 0) + sash.rx + sash.rWidth === conf.Width
                    ) {
                        if (!firstFillingInDoorTopLightSashesId) {
                            firstFillingInDoorTopLightSashesId = firstFillingIdInSash;
                        }
                        isSingleFillingInDoorTopLightSashes =
                            isSingleFillingInDoorTopLightSashes
                            && isSingleFillingInSash
                            && firstFillingInDoorTopLightSashesId === firstFillingIdInSash;
                    } else {
                        if (!firstFillingInDoorLeftLightSashesId) {
                            firstFillingInDoorLeftLightSashesId = firstFillingIdInSash;
                        }
                        isSingleFillingInDoorLeftLightSashes =
                            isSingleFillingInDoorLeftLightSashes
                            && isSingleFillingInSash
                            && firstFillingInDoorLeftLightSashesId === firstFillingIdInSash;
                    }
                    isSingleFillingInDoorLightSashes =
                        isSingleFillingInDoorLightSashes
                        && firstFillingInDoorLightSashesId === firstFillingIdInSash;
                } else if (
                    sash.nearMullions.right === -1
                    && ((frame && frame.x) || 0) + sash.rx > 0
                ) {
                    if (!firstFillingInDoorLightSashesId) {
                        firstFillingInDoorLightSashesId = firstFillingIdInSash;
                    }
                    if (!firstFillingInDoorRightLightSashesId) {
                        firstFillingInDoorRightLightSashesId = firstFillingIdInSash;
                    }
                    isSingleFillingInDoorRightLightSashes =
                        isSingleFillingInDoorRightLightSashes
                        && isSingleFillingInSash
                        && firstFillingInDoorRightLightSashesId === firstFillingIdInSash;
                    isSingleFillingInDoorLightSashes =
                        isSingleFillingInDoorLightSashes
                        && firstFillingInDoorLightSashesId === firstFillingIdInSash;
                }
            }

            sash.oneGlazing = isSingleFillingInSash;
            isSingleFilling =
                isSingleFilling && isSingleFillingInSash && firstFillingId === firstFillingIdInSash;
        });

        conf.OneGlazing = isSingleFilling;
        defaultConf.OneGlazing = isSingleFilling;
        if (isSingleFilling) {
            defaultConf.Glazing = { id: firstFillingId };
        }
        conf.OneFilling = {
            window: false,
            doorActive: false,
            doorActiveInner: false,
            doorPassive: false,
            doorLight: false,
            doorTopLight: false,
            doorLeftLight: false,
            doorRightLight: false,
        };
        if (isSingleFillingInWindowSashes) {
            conf.OneFilling.window = firstFillingInWindowSashesId || false;
        }
        if (isSingleFillingInDoorActiveSashes) {
            conf.OneFilling.doorActive = firstFillingInDoorActiveSashesId || false;
            if (DoorActiveConfiguration.is(conf)) {
                const filling = this.getFillingFromId(firstFillingInDoorActiveSashesId || '');
                if (filling && this.isDoorPanel(filling)) {
                    conf.Model = filling;
                    conf.ModelOptions.panelType = firstPanelType;
                    conf.ModelOptions.panelGlazing = firstPanelGlazing;

                    const innerFilling = this.getFillingFromId(
                        firstInnerPanelInDoorActiveSashesId || ''
                    );
                    if (innerFilling) {
                        conf.ModelOptions.panelInner = core.copy(innerFilling);
                    }
                }
            }
        }
        if (isSingleInnerPanelInDoorActiveSashes) {
            conf.OneFilling.doorActiveInner = firstInnerPanelInDoorActiveSashesId || false;
        }
        if (isSingleFillingInDoorPassiveSashes) {
            conf.OneFilling.doorPassive = firstFillingInDoorPassiveSashesId || false;
            if (DoorActiveConfiguration.is(conf)) {
                const filling = this.getFillingFromId(firstFillingInDoorPassiveSashesId || '');
                if (filling && this.isDoorPanel(filling)) {
                    conf.ModelOptions.passiveSashModel = filling;
                    conf.ModelOptions.passivePanelGlazing = firstPassivePanelGlazing;
                }

                const innerFilling = this.getFillingFromId(
                    firstInnerFillingInDoorPassiveSashesId || ''
                );
                if (innerFilling) {
                    conf.ModelOptions.passiveInnerSashModel = core.copy(innerFilling);
                }
            }
        }
        if (isSingleFillingInDoorLightSashes) {
            conf.OneFilling.doorLight = firstFillingInDoorLightSashesId || false;
        }
        if (isSingleFillingInDoorTopLightSashes) {
            conf.OneFilling.doorTopLight = firstFillingInDoorTopLightSashesId || false;
        }
        if (isSingleFillingInDoorLeftLightSashes) {
            conf.OneFilling.doorLeftLight = firstFillingInDoorLeftLightSashesId || false;
        }
        if (isSingleFillingInDoorRightLightSashes) {
            conf.OneFilling.doorRightLight = firstFillingInDoorRightLightSashesId || false;
        }

        this.eventBusService.post({
            key: 'icc-redraw',
            value: {},
        });
    }

    checkSingleFillingColors(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        defaultConf: any
    ) {
        if (DoorActiveConfiguration.is(conf)) {
            const doorSash = this.getDoorSash(conf, 'doorActive');
            if (doorSash) {
                conf.ModelOptions.selectedColor =
                    (doorSash.glazing
                        && doorSash.glazing.selectedColor)
                    || {};
                conf.ModelOptions.selectedColorSecond =
                    (doorSash.glazing
                        && doorSash.glazing.selectedColorSecond)
                    || {};

                conf.ModelOptions.innerSelectedColor =
                    (doorSash.panelInner
                        && doorSash.panelInner.selectedColor)
                    || {};
                conf.ModelOptions.innerSelectedColorSecond =
                    (doorSash.panelInner
                        && doorSash.panelInner.selectedColorSecond)
                    || {};
            }

            const doorPassiveSash = this.getDoorSash(conf, 'doorPassive');
            if (doorPassiveSash) {
                conf.ModelOptions.passiveSelectedColor =
                    (doorPassiveSash.glazing
                        && doorPassiveSash.glazing.selectedColor)
                    || {};
                conf.ModelOptions.passiveSelectedColorSecond =
                    (doorPassiveSash.glazing
                        && doorPassiveSash.glazing.selectedColorSecond)
                    || {};

                conf.ModelOptions.passiveInnerSelectedColor =
                    (doorPassiveSash.panelInner
                        && doorPassiveSash.panelInner.selectedColor)
                    || {};
                conf.ModelOptions.passiveInnerSelectedColorSecond =
                    (doorPassiveSash.panelInner
                        && doorPassiveSash.panelInner.selectedColorSecond)
                    || {};
            }
        }
    }

    getMatchingFillingsForModal(
        field,
        conf: WindowActiveConfiguration,
        sash?,
    ): {matchingFillings: any[], selectedFilling: string | null, bondedGlazing: boolean, noDivInSash: boolean } {
        let matchingFillings = [];
        let selectedFilling: string | null = null;
        let bondedGlazing = false;
        let noDivInSash = true;
        if (typeof field === 'undefined' || field === 'default') {
            matchingFillings = this.getMatchingFillings(conf);
            selectedFilling = conf.Sashes[0].glazing.id || null;
            bondedGlazing = conf.Sashes[0].bondedGlazing;
        } else if (field === 'window') {
            matchingFillings = this.getMatchingFillingsForWindowSashes(conf);
            selectedFilling = conf.OneFilling.window || null;
            bondedGlazing = conf.OneBondedGlazingPerSash.window;
        } else if (field === 'doorActive') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, 'doorActive');
            selectedFilling =
                conf.OneFilling.doorActive
                || conf.Sashes.find(s => ['DRA', 'DOA'].includes(s.type.type)).glazing.id
                || null;
            bondedGlazing = conf.OneBondedGlazingPerSash.doorActive;
            noDivInSash = conf.Sashes.filter(s => ['DRA', 'DOA'].includes(s.type.type)).every(
                s => s.intSashes.length < 2
            );
        } else if (field === 'doorPassive') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, 'doorPassive');
            selectedFilling =
                conf.OneFilling.doorPassive
                || conf.Sashes.find(s => ['DRP', 'DOP'].includes(s.type.type)).glazing.id
                || null;
            bondedGlazing = conf.OneBondedGlazingPerSash.doorPassive;
            noDivInSash = conf.Sashes.filter(s => ['DRP', 'DOP'].includes(s.type.type)).every(
                s => s.intSashes.length < 2
            );
        } else if (field === 'doorTopLight') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, 'doorTopLight');
            selectedFilling = conf.OneFilling.doorTopLight || null;
            bondedGlazing = conf.OneBondedGlazingPerSash.doorTopLight;
        } else if (field === 'doorLeftLight') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, 'doorLeftLight');
            selectedFilling = conf.OneFilling.doorLeftLight || null;
            bondedGlazing = conf.OneBondedGlazingPerSash.doorLeftLight;
        } else if (field === 'doorRightLight') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, 'doorRightLight');
            selectedFilling = conf.OneFilling.doorRightLight || null;
            bondedGlazing = conf.OneBondedGlazingPerSash.doorRightLight;
        } else {
            matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
            selectedFilling = field.glazing.id;
            bondedGlazing = field.bondedGlazing;
        }

        matchingFillings = this.getUniqueOrderedFillings(matchingFillings);

        return {matchingFillings, selectedFilling, bondedGlazing, noDivInSash};
    }

    async openModal(
        field,
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration,
        sash?,
        callback?
    ) {
        const {matchingFillings, selectedFilling, bondedGlazing, noDivInSash} = this.getMatchingFillingsForModal(field, conf, sash);

        const user = this.userService.get();
        const modalInstance = this.modalService.open({
            templateUrl: 'modalGlazing.html',
            controller: 'ModalGlazingCtrl as mglazing',
            pageComponent: FillingsListPageComponent,
            resolve: {
                fillings: () => matchingFillings,
                fillingsCategories: () => this.fillingsCategories,
                glassTypes: () => this.glassTypes,
                glassTypeVariants: () => this.glassTypeVariants,
                modalData: () => this.modalGlassTypeData,
                selGlassType: () => this.getFillingFromId(selectedFilling),
                selectFor: () => sash || field,
                noDivInSash: () => noDivInSash,
                hasGlasses: () => this.hasGlasses,
                bondedGlazing: () => bondedGlazing,
                b2c: () => !user || !user.access || user.access === 'klient',
                onlyGlazingList: () =>
                    field === 'doorTopLight'
                    || field === 'doorLeftLight'
                    || field === 'doorRightLight',
                filters$: () =>
                    new BehaviorSubject<any>({
                        selectedFilling: null,
                        filters: {
                            thermalTransmittance: 0,
                            glassCount: 0,
                            securityLevelOutside: 0,
                            securityLevelInside: 0,
                            noiseProtection: 0,
                            glassType: '0',
                            glassTypeVariant: 0,
                        },
                        classicFilters: null,
                    }),
                addGlazingUnit: () => this.addGlazingUnit.bind(this),
            },
        });

        const selectedData = await modalInstance.result;
        if (selectedData) {
            selectedData.glass.selected = true;
            if (typeof field !== 'undefined' && Common.isString(field) && field !== 'default') {
                this.setFillingInFilteredSashes(
                    conf,
                    selectedData.glass,
                    false,
                    this.getFilterForField(field, conf)
                );
            } else if (typeof sash === 'undefined' || Common.isString(sash)) {
                this.setFillingInAllSashes(conf, selectedData.glass);
            } else {
                this.setFillingInSash(field, sash, selectedData.glass, conf);
            }
            if (
                selectedData.glass.custom
                && typeof core.fId(conf.CustomFillings, selectedData.glass.id) === 'undefined'
            ) {
                conf.CustomFillings.push(selectedData.glass);
                this.clearCustomFillings(conf, defaultConf);
            }

            if (typeof sash === 'undefined' || Common.isString(sash)) {
                this.bondedGlazingService.setBondedGlazingInAllSashes(
                    conf,
                    selectedData.bondedGlazing
                );
            } else {
                this.bondedGlazingService.setBondedGlazingInSash(
                    field,
                    sash,
                    selectedData.bondedGlazing
                );
            }

            this.modalGlassTypeData.glassTab = selectedData.glassTab;
            this.modalGlassTypeData.selectedSecurity = selectedData.selectedSecurity;
            this.modalGlassTypeData.selectedOrnament = selectedData.selectedOrnament;
            this.modalGlassTypeData.selectedUg = selectedData.selectedUg;
            this.modalGlassTypeData.selectedRw = selectedData.selectedRw;
            if (this.config().IccConfig.Configurators.dependencies) {
                this.eventBusService.post({ key: 'processDependencies', value: null });
            }
            this.eventBusService.post({
                key: 'changedFillings',
                value: {},
            });
            if (callback) {
                callback();
            }
        }

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    clearCustomFillings(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        const ids = [];

        conf.Sashes.forEach(sash => {
            if (sash.intSashes.length) {
                sash.intSashes.forEach(isash => {
                    if (
                        isash.glazing.custom
                        && typeof core.fId(ids, isash.glazing.id) === 'undefined'
                    ) {
                        ids.push(isash.glazing.id);
                    }
                });
            } else {
                if (sash.glazing.custom && typeof core.fId(ids, sash.glazing.id) === 'undefined') {
                    ids.push(sash.glazing.id);
                }
            }
        });

        conf.CustomFillings = this.glazingUnitsService.fixGlazingUnits(conf.CustomFillings.filter(el => ids.indexOf(el.id) > -1));
        defaultConf.CustomFillings = core.copy(conf.CustomFillings);
    }

    openModelModal(field, conf: DoorActiveConfiguration, defaultConf: DoorActiveConfiguration) {
        let matchingFillings = [];
        let selectedFilling: string | null = null;
        let noDivInSash = true;
        let fillingsViewSide = 'outer';
        if (field === 'doorActive') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, 'doorActive').filter(
                f => f.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
            );
            selectedFilling =
                conf.OneFilling.doorActive
                || conf.Sashes.find(s => ['DRA', 'DOA'].includes(s.type.type)).glazing.id
                || null;
            noDivInSash = conf.Sashes.filter(s => ['DRA', 'DOA'].includes(s.type.type)).every(
                s => s.intSashes.length < 2
            );
        } else if (field === 'doorActiveInner') {
            const outerFillingId = conf.Sashes.find(s => ['DRA', 'DOA'].includes(s.type.type))?.glazing?.id;
            matchingFillings = this.getMatchingInnerPanelsForDoorSashesFiltered(conf, true);
            if (conf.System.door_type) {
                matchingFillings = matchingFillings.filter(p => p.base_side === "outside" && Number(p.id) === Number(outerFillingId)
                || p.base_side === "any" || p.base_side === "inside");
            }
            selectedFilling =
                conf.OneFilling.doorActiveInner
                || conf.Sashes.find(s => ['DRA', 'DOA'].includes(s.type.type)).glazing.id
                || null;
            noDivInSash = conf.Sashes.filter(s => ['DRA', 'DOA'].includes(s.type.type)).every(
                s => s.intSashes.length < 2
            );
            fillingsViewSide = 'inner';
        } else if (field === 'doorPassive') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, 'doorPassive').filter(
                f => f.type === (conf.System.door_type ? 'door_panels' : 'deco_panels')
            );
            selectedFilling =
                conf.OneFilling.doorPassive
                || conf.Sashes.find(s => ['DRP', 'DOP'].includes(s.type.type)).glazing.id
                || null;
            noDivInSash = conf.Sashes.filter(s => ['DRP', 'DOP'].includes(s.type.type)).every(
                s => s.intSashes.length < 2
            );
        }

        matchingFillings = this.getUniqueOrderedFillings(matchingFillings);

        const modalInstance = this.modalService.open({
            pageComponent: ModelListPageComponent,
            resolve: {
                fillings: () => matchingFillings,
                fillingsCategories: () => this.fillingsCategories,
                selectedFilling: () => this.getFillingFromId(selectedFilling),
                fillingsViewSide: () => fillingsViewSide,
                selectFor: () => field,
                noDivInSash: () => noDivInSash,
                hasGlasses: () => this.hasGlasses,
            },
        });

        modalInstance.result.then(selectedData => {
            if (selectedData) {
                if (typeof field !== 'undefined' && Common.isString(field)) {
                    this.setDecorativePanelInDoorSashes(
                        conf,
                        defaultConf,
                        selectedData.filling,
                        field
                    );
                } else {
                    this.setFillingInAllSashes(conf, selectedData.filling);
                }

                if (this.config().IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                }
                this.eventBusService.post({
                    key: 'changedFillings',
                    value: {},
                });
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    openModalPanelOptions(field, conf: DoorActiveConfiguration, sash?) {
        const fillingsData = this.getPanelGlazingsByFieldType(field, conf, sash);
        const matchingFillings = this.getMatchingFillingVariantsForField(conf, field, fillingsData['sash']);
        sash = fillingsData['sash'];

        const panelGlazings = fillingsData['panelGlazings'];
        const filling = fillingsData['filling'];

        return this.modalService
            .open({
                templateUrl: 'modalDecoPanelOptions.html',
                controller: 'ModalDecoPanelOptionsCtrl as $ctrl',
                pageComponent: DecoPanelOptionsPageComponent,
                resolve: {
                    fillings: () => panelGlazings,
                    fillingsCategories: () => this.fillingsCategories,
                    modalData: () => this.modalGlassTypeData,
                    selGlazing: () => sash.panelGlazing,
                    panelType: () => sash.panelType,
                    filling: () => filling,
                    filteredFillings: () => matchingFillings,
                    openFillingModal: () => this.openModalPanelGlazings.bind(this),
                    options$: () =>
                        new BehaviorSubject({
                            selGlazing: sash.panelGlazing,
                            panelType: sash.panelType,
                            filling: filling,
                        }),
                },
            })
            .result.then(selectedData => {
                if (selectedData) {
                    if (field === 'door' && conf.Sashes.length === 0) {
                        conf.Model = selectedData.filling;
                        conf.ModelOptions.panelGlazing = selectedData.selGlazing;
                        conf.ModelOptions.panelType = selectedData.panelType;
                    } else {
                        selectedData.filling.selectedColor =
                            sash.intSashes[0].glazing.selectedColor;
                        selectedData.filling.selectedColorSecond =
                            sash.intSashes[0].glazing.selectedColorSecond;
                        if (field !== 'doorActiveInner') {
                            this.setFillingInSash(sash, sash, selectedData.filling, conf);
                        }
                        if (typeof field === 'undefined' || field === 'default') {
                            this.setPanelOptionsInAllSashes(
                                conf,
                                selectedData.selGlazing,
                                selectedData.panelType
                            );
                        } else {
                            this.setPanelGlazingInSash(sash, selectedData.selGlazing);
                            this.setPanelTypeInSash(sash, selectedData.panelType);
                            this.setDecoPanelDefaults(sash, sash, conf);
                        }
                    }
                }
            });
    }

    changePanelGlazing(field, conf: DoorActiveConfiguration, sash?) {
        const fillingsData = this.getPanelGlazingsByFieldType(field, conf, sash);
        const panelGlazings = fillingsData['panelGlazings'];
        const filling = fillingsData['filling'];
        sash = fillingsData['sash'];

        if (panelGlazings.length === 0) {
            return Promise.resolve();
        }
         else if (conf.System.door_type && field !== 'doorActiveInner') {
            return this.openModalPanelGlazings(panelGlazings, sash.panelGlazing, conf).then(
                result => {
                    if (result) {
                        if (field === 'door' && conf.Sashes.length === 0) {
                            conf.ModelOptions.panelGlazing = result.glazing;
                        } else {
                            if(field === 'doorPassive') {
                                conf.ModelOptions.passivePanelGlazing = result.glazing;
                            }
                            if (typeof field === 'undefined' || field === 'default') {
                                this.setPanelGlazingsInAllSashes(conf, result.glazing);
                            } else {
                                this.setPanelGlazingInSash(sash, result.glazing);
                                if (result?.glazing?.type !== 'panels_glazing') {
                                    this.setDecoPanelDefaults(sash, sash, conf);
                                }
                            }
                        }
                    }
                }
            );
        }
    }

    openModalPanelGlazings(
        panelGlazings: IccFilling[],
        selectedFilling: Partial<IccFilling>,
        conf: WindowActiveConfiguration
    ) {
        return this.modalService.open({
            pageComponent: PanelGlazingsListPageComponent,
            resolve: {
                fillings: () => panelGlazings,
                selectedFilling: () => selectedFilling,
            },
        }).result;
    }

    getPanelGlazingsByFieldType(field, conf, sash?) {
        sash = sash ? sash : this.getDoorSash(conf, field);
        let panelGlazings = [];
        let filling = {};
        if (typeof field === 'undefined' || field === 'default') {
            panelGlazings = this.getMatchingPanelGlazings(conf);
            sash = conf.Sashes[0];
            filling = conf.Sashes[0].glazing;
        } else if (field === 'doorActive') {
            panelGlazings = this.getMatchingPanelGlazingsForDoorSashes(conf, true);
            sash = conf.Sashes.filter(s => s.type.type === 'DRA' || s.type.type === 'DOA')[0];
            filling = sash.glazing;
        } else if (field === 'doorActiveInner') {
            panelGlazings = this.getMatchingInnerPanelsForDoorSashes(conf, true);
            sash = conf.Sashes.filter(s => s.type.type === 'DRA' || s.type.type === 'DOA')[0];
            filling = sash.panelInner;
        } else if (field === 'doorPassive') {
            panelGlazings = this.getMatchingPanelGlazingsForDoorSashes(conf, false);
            sash = conf.Sashes.filter(s => s.type.type === 'DRP' || s.type.type === 'DOP')[0];
            filling = sash.glazing;
        } else if (field === 'door') {
            if (conf.Sashes.length === 0) {
                panelGlazings = this.getMatchingPanelGlazingsForModel(conf);
                sash = {
                    ...conf.ModelOptions,
                    glazing: conf.Model,
                };
                filling = conf.Model;
            } else {
                const doorType = ['DRA', 'DOA', 'DRP', 'DOP'];
                panelGlazings = this.getMatchingPanelGlazingsFor(
                    conf,
                    s => s.type && doorType.indexOf(s.type.type) > -1
                );
                sash = conf.Sashes.filter(s => s.type && doorType.indexOf(s.type.type) > -1)[0];
                filling = sash.glazing;
            }
        } else {
            panelGlazings = this.getMatchingPanelGlazingsForSash(field);
            filling = field.glazing;
        }

        return {
            panelGlazings,
            filling,
            sash,
        };
    }

    getMatchingFillings(conf: WindowActiveConfiguration) {
        return this.getMatchingFillingsFor(conf, () => true);
    }

    validateFillingColors(conf: WindowActiveConfiguration, sash: ActiveSash | 'activeDoorSash') {
        this.newColorsService.validateElementColor(conf, {
            element: 'filling',
            sash,
            side: 'outer',
        });
        if((sash instanceof ActiveSash && sash.glazing.available_second_color) || sash === 'activeDoorSash')  {
            this.newColorsService.validateElementColor(conf, {
                element: 'filling',
                sash,
                side: 'outer',
                alt: true,
            });
        } else {
            this.newColorsService.clearElementColor(conf, {
                element: 'filling',
                sash,
                side: 'outer',
                alt: true,
            });
        }
    }

    getDoorSash(conf: WindowActiveConfiguration | DoorActiveConfiguration, boxType?: string) {
        let sash: ActiveSash;
        if (conf.Sashes) {
            if (typeof boxType === 'undefined' || boxType === 'default') {
                sash = conf.Sashes[0];
            } else if (boxType === 'doorActive' || boxType === 'doorActiveInner') {
                sash = conf.Sashes.find(s => s.type.type === 'DRA' || s.type.type === 'DOA');
            } else if (boxType === 'doorPassive') {
                sash = conf.Sashes.find(s => s.type.type === 'DRP' || s.type.type === 'DOP');
            } else if (boxType === 'door') {
                sash = conf.Sashes.find(
                    s =>
                        s.type.type === 'DRA'
                        || s.type.type === 'DRP'
                        || s.type.type === 'DOA'
                        || s.type.type === 'DOP'
                );
            } else if (boxType === 'window') {
                sash = conf.Sashes.find(
                    s =>
                        s.type.type !== 'DRA'
                        && s.type.type !== 'DRP'
                        && s.type.type !== 'DOA'
                        && s.type.type !== 'DOP'
                );
            } else if (boxType === 'topLight') {
                sash = conf.Sashes.find(s => {
                    const frame = conf.Frames.find(f => f.id === s.frameId);
                    return (
                        s.type.type === 'F'
                        && s.nearMullions.left === -1
                        && ((frame && frame.x) || 0) + s.rx === 0
                        && s.nearMullions.top === -1
                        && ((frame && frame.x) || 0) + s.rx + s.rWidth === conf.Width
                    );
                });
            } else if (boxType === 'leftLight') {
                sash = conf.Sashes.find(s => {
                    const frame = conf.Frames.find(f => f.id === s.frameId);
                    return (
                        s.type.type === 'F'
                        && s.nearMullions.left === -1
                        && ((frame && frame.x) || 0) + s.rx === 0
                        && (s.nearMullions.top !== -1
                            || ((frame && frame.x) || 0) + s.rx + s.rWidth !== conf.Width)
                    );
                });
            } else if (boxType === 'rightLight') {
                sash = conf.Sashes.find(s => {
                    const frame = conf.Frames.find(f => f.id === s.frameId);
                    return (
                        s.type.type === 'F'
                        && s.nearMullions.right === -1
                        && ((frame && frame.x) || 0) + s.rx > 0
                    );
                });
            }
        }
        return sash;
    }

    getFilterForField(field, conf: WindowActiveConfiguration) {
        let sashFilter = sash => true;
        if (field === 'window') {
            sashFilter = sash => ['DRA', 'DRP', 'DOA', 'DRP'].indexOf(sash.type.type) === -1;
        } else if (field === 'doorActive' || field === 'doorActiveInner') {
            sashFilter = sash => ['DRA', 'DOA'].indexOf(sash.type.type) > -1;
        } else if (field === 'doorPassive') {
            sashFilter = sash => ['DRP', 'DOP'].indexOf(sash.type.type) > -1;
        } else if (field === 'door') {
            sashFilter = sash => ['DRA', 'DOA', 'DRP', 'DOP'].indexOf(sash.type.type) > -1;
        } else if (field === 'doorLight') {
            sashFilter = sash => sash.type.type === 'F';
        } else if (field === 'doorTopLight') {
            sashFilter = sash => {
                const frame = conf.Frames.find(f => f.id === sash.frameId);
                return (
                    sash.type.type === 'F'
                    && sash.nearMullions.left === -1
                    && ((frame && frame.x) || 0) + sash.rx === 0
                    && sash.nearMullions.top === -1
                    && ((frame && frame.x) || 0) + sash.rx + sash.rWidth === conf.Width
                );
            };
        } else if (field === 'doorLeftLight') {
            sashFilter = sash => {
                const frame = conf.Frames.find(f => f.id === sash.frameId);
                return (
                    sash.type.type === 'F'
                    && sash.nearMullions.left === -1
                    && ((frame && frame.x) || 0) + sash.rx === 0
                    && (sash.nearMullions.top !== -1
                        || ((frame && frame.x) || 0) + sash.rx + sash.rWidth !== conf.Width)
                );
            };
        } else if (field === 'doorRightLight') {
            sashFilter = sash => {
                const frame = conf.Frames.find(f => f.id === sash.frameId);
                return (
                    sash.type.type === 'F'
                    && sash.nearMullions.right === -1
                    && ((frame && frame.x) || 0) + sash.rx > 0
                );
            };
        }

        return sashFilter;
    }

    private getMatchingFillingsForWindowSashes(conf: WindowActiveConfiguration) {
        return this.getMatchingFillingsFor(conf, this.getFilterForField('window', conf));
    }

    getMatchingFillingsForDoorSashes(
        conf: WindowActiveConfiguration,
        type:
            | 'doorActive'
            | 'doorActiveInner'
            | 'doorPassive'
            | 'door'
            | 'doorLight'
            | 'doorTopLight'
            | 'doorLeftLight'
            | 'doorRightLight' = 'door'
    ) {
        return this.getMatchingFillingsFor(conf, this.getFilterForField(type, conf));
    }

    getMatchingFillingVariantsForField(
        conf: WindowActiveConfiguration,
        field: string | ActiveSash,
        sash?: ActiveSash,
    ) {
        let matchingFillings = [];
        if (typeof field === 'string') {
            matchingFillings = this.getMatchingFillingsFor(conf, this.getFilterForField(field, conf))
        } else {
            matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
        }
        matchingFillings = matchingFillings.filter(
            el => el.id.split('.')[0] === sash.glazing.id.split('.')[0]
        );

        matchingFillings = Object.values(matchingFillings.reduce((acu, filling) => {
            if (!acu[filling.panel_type] || Number(acu[filling.panel_type].thickness_mm) < Number(filling.thickness_mm)) {
                acu[filling.panel_type] = filling;
            }
            return acu;
        }, {}));

        return matchingFillings;
    }

    checkGlazing(glazing, glazingRestricts, width = 0, height = 0, area: any = 0) {
        if (!isArray(glazingRestricts)) {
            return true
        }
        const restrict = glazingRestricts.find(glazingRestrict =>
            parseInt(glazing.thinkness_glass) === parseInt(glazingRestrict.thickness)
            && parseInt(glazing.frame_width) >= parseInt(glazingRestrict.frame_width)
            );
        if (isObject(restrict)) {
            let ratio = restrict.ratio.split(':');
            ratio = ratio[1] / ratio[0];
            const areaInCorrect = parseFloat(area) > parseFloat(restrict.area);
            const lengthMinInCorrect = (width < restrict.length_min) || (height < restrict.length_min);
            const lengthMaxInCorrect = (width > restrict.length_max) || (height > restrict.length_max);
            const rationInCorrect = (width >= height && width / height > ratio) || (height / width > ratio);
            return !(areaInCorrect || lengthMinInCorrect || lengthMaxInCorrect || rationInCorrect);
        }
        return true;
    }

    updateStepLights(conf: WindowActiveConfiguration) {
        if (this.getMatchingFillingsForDoorSashes(conf, 'doorLight').length <= 1) {
            this.stepsService.disable('lightsglazing');
        } else {
            this.stepsService.enable('lightsglazing');
        }
    }

    private getMatchingFillingsFor(conf: WindowActiveConfiguration, filter: (sash) => boolean) {

        let matchingFillings = this.getMatchingFillingsGeneral(conf);
        (conf.Sashes || []).filter(filter).forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingFillings = this.getCommonFillingsInSash(
                    matchingFillings,
                    intSash,
                    sash,
                    conf
                );
            });
            if (sash.intSashes.length === 0) {
                matchingFillings = this.getCommonFillingsInSash(matchingFillings, sash, sash, conf);
            }
        });
        return matchingFillings;
    }

    private getMatchingFillingsInSash(
        field,
        sash,
        conf: WindowActiveConfiguration | DoorActiveConfiguration
    ) {
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
        ) {
            const matchingThickness = this.glazingBeadsService.getMatchingFillingThicknessInSash(
                field,
                sash,
                conf
            );

            const matchingThicknessPanelDouble = this.glazingBeadsService.getMatchingFillingThicknessInSash(
                field,
                sash,
                conf,
                'panel_type_double'
            );

            const matchingThicknessPanelInner = this.glazingBeadsService.getMatchingFillingThicknessInSash(
                field,
                sash,
                conf,
                'panel_type_inner'
            );

            const matchingThicknessPanelOuter = this.glazingBeadsService.getMatchingFillingThicknessInSash(
                field,
                sash,
                conf,
                'panel_type_outer'
            );

            const matchingFillings = this.fillings.filter(
                filling =>
                    (sash.type
                        && sash.type.type !== 'F'
                        && ((this.isDoorPanel(filling)
                            && (filling.type === 'deco_panels'
                                && this.isDecoPanelMatchToDimensions(filling, field, sash, conf)
                                && this.isDecoPanelMatchToDepth(filling, {
                                    panel_type_inset: matchingThickness,
                                    panel_type_double: matchingThicknessPanelDouble,
                                    panel_type_inner: matchingThicknessPanelInner,
                                    panel_type_outer: matchingThicknessPanelOuter,
                                })
                            ))
                            || (filling.type === 'door_panels'
                                && this.isDoorPanelMatchToDoorSizes(
                                    filling,
                                    sash,
                                    conf as DoorActiveConfiguration
                                ))))
                    || (!this.isDoorPanel(filling)
                        && filling.type !== 'panels_glazing'
                        && matchingThickness.some(
                            range =>
                                Math.round(Number(filling.thickness_mm)) >= range.min
                                && Math.round(Number(filling.thickness_mm)) <= range.max
                        )
                        && this.isFillingMatchToSashType(conf, sash, filling))
            );

            this.issuesService.unregister(
                `no-matching-fillings-in-sash-${field.id}-${sash.id}`,
                conf
            );
            if (matchingFillings.length === 0) {
                this.issuesService.registerDataProblem(
                    `no-matching-fillings-in-sash-${field.id}-${sash.id}`,
                    'Brak pasujących wypełnień',
                    conf,
                    {
                        level: IssueLevel.FATAL,
                        extra: {
                            matchingThickness: core.stringJson(matchingThickness),
                            sashType: sash.type.type,
                            systemId: conf.System.id,
                            systemName: conf.System.name,
                            systemType: conf.System.type,
                        },
                    }
                );
            }
            return matchingFillings;
        }
        return [];
    }

    private doorPanelPassiveMatchesActive(
        filling: IccFilling,
        conf: DoorActiveConfiguration
    ) {
        const selectedActiveSashDoorPanel = conf.Model;
        const fillingId = filling.id && String(filling.id).split('.')[0]
        if (
            selectedActiveSashDoorPanel
            && Array.isArray(selectedActiveSashDoorPanel.door_panel_passive_ids)
            && selectedActiveSashDoorPanel.door_panel_passive_ids.includes(fillingId)
        ) {
            return true;
        }

        return false;
    }

    private getMatchingFillingsGeneral(conf: WindowActiveConfiguration | DoorActiveConfiguration) {
        if (this.validationService.isValidElements(conf, ['loadedFillings'])) {
            const matchingFillings = this.fillings.filter(
                filling =>
                    this.isDoorPanel(filling)
                    || (!this.isDoorPanel(filling)
                        && filling.type !== 'panels_glazing'
                        && this.isFillingMatchToSashTypeGeneral(conf, filling)
                    )
            );

            this.issuesService.unregister(`no-matching-fillings-in-general`, conf);
            if (matchingFillings.length === 0) {
                this.issuesService.registerDataProblem(
                    `no-matching-fillings-in-general`,
                    'Brak pasujących wypełnień',
                    conf,
                    {
                        level: IssueLevel.FATAL,
                        extra: {
                            systemId: conf.System.id,
                            systemName: conf.System.name,
                            systemType: conf.System.type,
                        },
                    }
                );
            }
            return matchingFillings;
        }
        return [];
    }

    private isFillingMatchToSashTypeGeneral(conf: WindowActiveConfiguration, filling: IccFilling): any {
        const notDoor = conf.type !== 'door'
            || ((conf as DoorActiveConfiguration).Model
            && (conf as DoorActiveConfiguration).Model.standard_fills)
        const notDoorSash = (sash) => ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) === -1;
        const isDoorLight = (sash) => conf.type === 'door' && notDoorSash(sash);
        const isSideLight = (sash) => {
            const frame = conf.Frames.find(f => f.id === sash.frameId);
            return isDoorLight(sash) && frame.y + sash.ry + sash.rHeight === conf.Height
        };
        const isTopLight = (sash) => {
            const frame = conf.Frames.find(f => f.id === sash.frameId);
            return isDoorLight(sash) && frame.y + sash.ry + sash.rHeight < conf.Height;
        };
        return (
            (
                notDoor
                && conf.Sashes.some(sash => notDoorSash(sash))
                && filling.conf.indexOf('window') > -1
            ) || conf.Sashes.some(sash => isSideLight(sash)) && filling.conf.indexOf('side-light') > -1
            || conf.Sashes.some(sash => isTopLight(sash)) && filling.conf.indexOf('top-light') > -1
            || (conf.Sashes.length === 0 && filling.conf.indexOf('window') > -1)
            || filling.conf.indexOf('door') > -1
        );
    }

    private isDecoPanelMatchToDimensions(filling: IccFilling, field: any, sash: any, conf: WindowActiveConfiguration) {
        const panelDimensions = this.getDecoPanelSpaceDimensions(field, sash, filling, conf);
        const panelWidthMin =
            filling.panel_width_min || this.decoPanelRestrictiveDimensions.minWidth;
        const panelHeightMin =
            filling.panel_height_min || this.decoPanelRestrictiveDimensions.minHeight;
        const panelWidthMax =
            filling.panel_width_max || this.decoPanelRestrictiveDimensions.maxWidth;
        const panelHeightMax =
            filling.panel_height_max || this.decoPanelRestrictiveDimensions.maxHeight;
        return (
            Number(panelWidthMin) <= panelDimensions.width
            && Number(panelWidthMax) >= panelDimensions.width
            && Number(panelHeightMin) <= panelDimensions.height
            && Number(panelHeightMax) >= panelDimensions.height
        );
    }

    private isDecoPanelMatchToDepth(filling: IccFilling, matchingThicknesses: Record<IccFilling['panel_type'], {
        min: number;
        max: number;
    }[]>) {
        const overlayThickness = this.getDecoPanelOverlayThickness(filling);
        return this.isAlwaysMatchProfile(filling) || matchingThicknesses[filling.panel_type].some(
            range =>
                Number(filling.thickness_mm) - overlayThickness >= range.min
                && Number(filling.thickness_mm) - overlayThickness <= range.max
        )
    }

    private isAlwaysMatchProfile(filling: IccFilling) {
        return filling.type === 'deco_panels'
            && (filling.panel_type === 'panel_type_double' || filling.panel_type === 'panel_type_inner')
            && Boolean(filling.always_match_profile);
    }

    private isDoorPanelMatchToDoorSizes(
        filling: IccFilling,
        sash: ActiveSash | null,
        conf: DoorActiveConfiguration,
        inner = false
    ) {
        if (sash && ['DRP', 'DOP'].indexOf(sash.type.type) > -1) {
            return (
                filling.door_sizes_ids
                && filling.door_sizes_ids.map(Number).includes(conf.doorSizes.passiveSashSizeId)
                && (!filling.max_shortening
                    || conf.doorSizes.shortening <= Number(filling.max_shortening))
                && this.doorPanelPassiveMatchesActive(filling, conf)
            );
        }
        if (!sash || ['DRA', 'DOA'].indexOf(sash.type.type) > -1) {
            const fillingMatchToDoorSize = sash && this.fillingSizeMatchToDoorSize(filling, sash, conf, inner);
            return (
                filling.door_sizes_ids
                && filling.door_sizes_ids.map(Number).includes(conf.doorSizes.sashSizeId)
                && (!filling.max_shortening
                    || conf.doorSizes.shortening <= Number(filling.max_shortening))
                && (fillingMatchToDoorSize || !sash)
            );
        }
        return false;
    }

    private fillingSizeMatchToDoorSize(
        filling: IccFilling,
        sash: ActiveSash | null,
        conf: DoorActiveConfiguration,
        inner = false) {
            const doorDataSashesSizeSash = filling.size_limit_type === 'sash_with_frame'
                ? this.getDoorDataSashesWithFrameSize(sash, conf)
                : this.getDoorDataSashesSize(sash, conf);
            const side = inner ? 'inner' : 'outer';

            return (!Number(filling.panel_width_max) || Number(filling.panel_width_max) >= doorDataSashesSizeSash[side].width)
                && (!Number(filling.panel_width_min) || Number(filling.panel_width_min) <= doorDataSashesSizeSash[side].width)
                && (!Number(filling.panel_height_max) || Number(filling.panel_height_max) >= doorDataSashesSizeSash[side].height)
                && (!Number(filling.panel_height_min) || Number(filling.panel_height_min) <= doorDataSashesSizeSash[side].height);
    }

    private getDoorDataSashesSize(
        sash: ActiveSash | null,
        conf: WindowActiveConfiguration | DoorActiveConfiguration) {

            const heightWithRebate = sash ? this.getSashHeightBasedOnHeight(conf) : Number.POSITIVE_INFINITY;
            const widthWithRebate = sash ? this.getSashWidthBasedOnWidth(conf) : Number.POSITIVE_INFINITY;

            const heightWithoutRebate = sash ? this.getSashHeightBasedOnHeight(conf, false) : Number.POSITIVE_INFINITY;
            const widthWithoutRebate = sash ? this.getSashWidthBasedOnWidth(conf, false) : Number.POSITIVE_INFINITY;
            const typeTypeDRA = sash?.type?.type === 'DRA';

            return {
                inner: {
                    height: typeTypeDRA ? heightWithRebate : heightWithoutRebate,
                    width: typeTypeDRA ? widthWithRebate : widthWithoutRebate
                },
                outer: {
                    height: typeTypeDRA ? heightWithoutRebate : heightWithRebate,
                    width: typeTypeDRA ? widthWithoutRebate : widthWithRebate
                }
            }
    }

    private getDoorDataSashesWithFrameSize(
        sash: ActiveSash | null,
        conf: WindowActiveConfiguration | DoorActiveConfiguration) {

            const height = conf.doorSizes.isStandardHeightSelected || conf.doorSizes.customHeight === 0
                ? sash ? sash.rHeight : Number.POSITIVE_INFINITY
                : conf.doorSizes.customHeight
            const width = conf.doorSizes.isStandardHeightSelected || conf.doorSizes.customWidth === 0
                ? sash ? sash.rWidth : Number.POSITIVE_INFINITY
                : conf.doorSizes.customWidth;

            return {
                inner: { height, width },
                outer: { height, width }
            }
    }

    private getDecoPanelSpaceDimensions(field, sash, filling, conf: WindowActiveConfiguration) {
        const sashDrawData =
            conf.drawData
            && conf.drawData.sash
            && conf.drawData.sash.find(s => s.sashId === field.id);
        const fillingDrawData =
            conf.drawData
            && conf.drawData.filling
            && conf.drawData.filling.find(s => s.sashId === field.id);
        let panelWidth = field.rWidth;
        let panelHeight = field.rHeight;
        if (sashDrawData) {
            panelWidth = sashDrawData.inner.rect.width;
            panelHeight = sashDrawData.inner.rect.height;

            if (filling.panel_type === 'panel_type_inset' && fillingDrawData?.insetData) {
                panelWidth = fillingDrawData.insetData.rect.width;
                panelHeight = fillingDrawData.insetData.rect.height;
            }
            if (
                filling.panel_type === 'panel_type_outer'
                || filling.panel_type === 'panel_type_double'
            ) {
                panelWidth = sashDrawData.outer.rect.width;
                panelHeight = sashDrawData.outer.rect.height;
            }
        }
        return {
            width: panelWidth,
            height: panelHeight,
        };
    }

    private getMatchingPanelGlazings(conf: WindowActiveConfiguration) {
        return this.getMatchingPanelGlazingsFor(
            conf,
            sash => ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) > -1
        );
    }

    private getMatchingPanelGlazingsForDoorSashes(conf: WindowActiveConfiguration, active = true) {
        const doorType = active ? ['DRA', 'DOA'] : ['DRP', 'DOP'];
        return this.getMatchingPanelGlazingsFor(
            conf,
            sash => doorType.indexOf(sash.type.type) > -1
        );
    }

    private getMatchingPanelGlazingsFor(
        conf: WindowActiveConfiguration,
        filter: (sash) => boolean
    ) {
        let matchingFillings = [];
            conf.Sashes.filter(filter).forEach(sash => {
                sash.intSashes.forEach(intSash => {
                    matchingFillings = this.getCommonPanelGlazingsInSash(matchingFillings, intSash);
                });
                if (sash.intSashes.length === 0) {
                    matchingFillings = this.getCommonPanelGlazingsInSash(matchingFillings, sash);
                }
            });
        return matchingFillings;
    }

    getMatchingInnerPanelsForDoorSashes(conf: DoorActiveConfiguration, active = true) {
        const doorType = active ? ['DRA', 'DOA'] : ['DRP', 'DOP'];
        return this.getMatchingInnerPanelsFor(conf, sash => doorType.indexOf(sash.type.type) > -1);
    }

    getMatchingInnerPanelsForDoorSashesFiltered(conf: DoorActiveConfiguration, active = true) {
        const doorType = active ? ['DRA', 'DOA'] : ['DRP', 'DOP'];
        return this.getMatchingInnerPanelsFor(
            conf,
            sash => doorType.indexOf(sash.type.type) > -1
        ).filter(f => f.type === (conf.System.door_type ? 'door_panels' : 'deco_panels'));
    }

    private getMatchingInnerPanelsFor(conf: DoorActiveConfiguration, filter: (sash) => boolean) {
        let matchingFillings = [];
        conf.Sashes.filter(filter).forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingFillings = this.getCommonInnerPanelsInSash(
                    matchingFillings,
                    intSash,
                    sash,
                    conf
                );
            });
            if (sash.intSashes.length === 0) {
                matchingFillings = this.getCommonInnerPanelsInSash(
                    matchingFillings,
                    sash,
                    sash,
                    conf
                );
            }
        });
        return matchingFillings;
    }

    private getMatchingPanelGlazingsForSash(field, conf = this.configurationsService.conf.Current) {
        return this.fillings.filter(
            filling =>
                filling.type === 'panels_glazing'
                && field.glazing
                && field.glazing.panel_glazings_ids
                && field.glazing.panel_glazings_ids.map(Number).indexOf(parseInt(filling.id)) > -1
        );
    }

    private getMatchingPanelGlazingsForModel(conf: DoorActiveConfiguration) {
        return this.fillings.filter(
            filling =>
                filling.type === 'panels_glazing'
                && conf.Model
                && conf.Model.panel_glazings_ids
                && conf.Model.panel_glazings_ids.map(Number).indexOf(parseInt(filling.id)) > -1
                && conf.Model.panel_glazings_ids.find(k =>
                    this.fillings.some(f => Number(f.id) == k)
                )
        );
    }

    private getMatchingInnerPanelsForSash(
        field,
        sash,
        conf: WindowActiveConfiguration | DoorActiveConfiguration
    ) {
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
            && (field.glazing
                && field.glazing.type === 'door_panels'
                && field.glazing.is_double_sided
                && field.glazing.is_double_sided.length > 0)
        ) {
            const matchingFillings = this.fillings.filter(
                filling =>
                    filling.type === 'door_panels'
                    && ((filling.is_flat && field.glazing.is_double_sided.includes('single'))
                        || (field.glazing.is_flat
                            && (filling.is_double_sided.includes('single') || filling.is_flat))
                        || (field.glazing.is_double_sided.includes('double')
                            && filling.id === field.glazing.id)
                        || (field.glazing.door_panel_ids
                            && (field.glazing.is_double_sided.includes('mixed')
                                && field.glazing.door_panel_ids
                                    .map(Number)
                                    .includes(parseInt(filling.id)))))
                    && this.isDoorPanelMatchToDoorSizes(
                        filling,
                        sash,
                        conf as DoorActiveConfiguration,
                        true
                    )
            );

            this.issuesService.unregister(
                `no-matching-fillings-in-sash-${field.id}-${sash.id}`,
                conf
            );
            if (matchingFillings.length === 0) {
                this.issuesService.registerDataProblem(
                    `no-matching-fillings-in-sash-${field.id}-${sash.id}`,
                    'Brak pasujących wypełnień',
                    conf,
                    {
                        level: IssueLevel.FATAL,
                        extra: {
                            sashType: sash.type.type,
                            systemId: conf.System.id,
                            systemName: conf.System.name,
                            systemType: conf.System.type,
                        },
                    }
                );
            }
            return matchingFillings;
        }
        return [];
    }

    private getMatchingInnerPanelsForModel(conf: DoorActiveConfiguration) {
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
            && (conf.Model
                && conf.Model.type === 'door_panels'
                && conf.Model.is_double_sided
                && conf.Model.is_double_sided.length > 0)
        ) {
            const matchingFillings = this.fillings.filter(
                filling =>
                    filling.type === 'door_panels'
                    && ((filling.is_flat && conf.Model.is_double_sided.includes('single'))
                        || (conf.Model.is_flat && filling.is_double_sided.includes('single'))
                        || (conf.Model.is_double_sided.includes('double')
                            && filling.id === conf.Model.id)
                        || (conf.Model.door_panel_ids
                            && (conf.Model.is_double_sided.includes('mixed')
                                && conf.Model.door_panel_ids
                                    .map(Number)
                                    .includes(parseInt(filling.id)))))
                    && this.isDoorPanelMatchToDoorSizes(
                        filling,
                        null,
                        conf as DoorActiveConfiguration
                    )
            );

            this.issuesService.unregister(`no-matching-fillings-in-model`, conf);
            if (matchingFillings.length === 0) {
                this.issuesService.registerDataProblem(
                    `no-matching-fillings-in-model`,
                    'Brak pasujących wypełnień',
                    conf,
                    {
                        level: IssueLevel.FATAL,
                        extra: {
                            systemId: conf.System.id,
                            systemName: conf.System.name,
                            systemType: conf.System.type,
                        },
                    }
                );
            }
            return matchingFillings;
        }
        return [];
    }

    private getMatchingPanelTypesForSash(sash: ActiveSash) {
        if (sash.glazing.type === 'door_panels') {
            const panelTypes = {
                Double: 'double',
                Outer: 'single',
                Inner: 'single',
                Mixed: 'mixed',
            };
            return Object.keys(panelTypes).reduce((prev, type) => {
                if (
                    sash.glazing.is_double_sided
                    && sash.glazing.is_double_sided.includes(panelTypes[type])
                ) {
                    prev.push(type);
                }
                return prev;
            }, []);
        } else {
            const panelTypes = {
                Inset: 'panel_type_inset',
                Outer: 'panel_type_outer',
                Double: 'panel_type_double',
                Inner: 'panel_type_inner',
            };
            return Object.keys(panelTypes).reduce((prev, type) => {
                if (sash.glazing.panel_type === panelTypes[type]) {
                    prev.push(type);
                }
                return prev;
            }, []);
        }
    }

    private getMatchingPanelType(matchingPanelTypes) {
        return matchingPanelTypes[0];
    }

    private getMatchingPanelTypeForSash(sash: ActiveSash, matchingPanelTypes: any[]) {
        if (
            !sash.glazing
            || !sash.glazing.id
            || sash.glazing.type !== 'door_panels'
            || !sash.panelInner
            || !sash.panelInner.id
        ) {
            return matchingPanelTypes[0];
        }
        if (sash.glazing.is_flat) {
            return 'Inner';
        } else if (sash.panelInner.is_flat) {
            return 'Outer';
        } else if (parseInt(sash.glazing.id) === parseInt(sash.panelInner.id)) {
            return 'Double';
        } else {
            return 'Mixed';
        }
    }

    private getMatchingPanelTypesForModel(conf: DoorActiveConfiguration) {
        if (conf.Model && conf.Model.type === 'door_panels') {
            const panelTypes = {
                Double: 'double',
                Outer: 'single',
                Inner: 'single',
                Mixed: 'mixed',
            };
            return Object.keys(panelTypes).reduce((prev, type) => {
                if (
                    conf.Model
                    && conf.Model.is_double_sided
                    && conf.Model.is_double_sided.includes(panelTypes[type])
                ) {
                    prev.push(type);
                }
                return prev;
            }, []);
        } else {
            const panelTypes = {
                Inset: 'panel_type_inset',
                Outer: 'panel_type_outer',
                Double: 'panel_type_double',
                Inner: 'panel_type_inner',
            };
            return Object.keys(panelTypes).reduce((prev, type) => {
                if (conf.Model && conf.Model.panel_type === panelTypes[type]) {
                    prev.push(type);
                }
                return prev;
            }, []);
        }
    }

    private getCommonFillingsInSash(
        matchingFillings,
        field,
        sash,
        conf: WindowActiveConfiguration
    ) {
        if (matchingFillings.length === 0) {
            matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
        } else {
            matchingFillings = this.getCommonFillings(
                matchingFillings,
                this.getMatchingFillingsInSash(field, sash, conf)
            );
        }
        return matchingFillings;
    }

    private getCommonFillings(list1: any[], list2: any[]) {
        return list1.filter(function(a) {
            return this.has(a.id);
        }, list2.reduce((hash, b) => hash.add(b.id), new Set()));
    }

    private getCommonPanelGlazingsInSash(matchingFillings, field) {
        if (matchingFillings.length === 0) {
            matchingFillings = this.getMatchingPanelGlazingsForSash(field);
        } else {
            matchingFillings = this.getCommonFillings(
                matchingFillings,
                this.getMatchingPanelGlazingsForSash(field)
            );
        }
        return matchingFillings;
    }

    private getCommonInnerPanelsInSash(
        matchingFillings,
        field,
        sash,
        conf: DoorActiveConfiguration
    ) {
        if (matchingFillings.length === 0) {
            matchingFillings = this.getMatchingInnerPanelsForSash(field, sash, conf);
        } else {
            matchingFillings = this.getCommonFillings(
                matchingFillings,
                this.getMatchingInnerPanelsForSash(field, sash, conf)
            );
        }
        return matchingFillings;
    }

    private isValidPanelType(sash) {
        return this.getMatchingPanelTypesForSash(sash).indexOf(sash.panelType) > -1;
    }

    private isValidPanelGlazing(sash) {
        const matchingPanelGlazings = this.getMatchingPanelGlazingsForSash(sash)
        .map(f => f.id?.toString());

        return (
            Boolean(sash.panelGlazing
            && matchingPanelGlazings.indexOf(sash.panelGlazing?.id?.toString()) > -1)
            || !matchingPanelGlazings.length && !sash.panelGlazing?.id
        );
    }

    private isValidPanelTypeForModel(conf: DoorActiveConfiguration) {
        return (
            conf.ModelOptions
            && this.getMatchingPanelTypesForModel(conf).indexOf(conf.ModelOptions.panelType) > -1
        );
    }

    private isValidPanelGlazingForModel(conf: DoorActiveConfiguration) {
        return (
            conf.ModelOptions.panelGlazing
            && this.getMatchingPanelGlazingsForModel(conf)
                .map(f => Number(f.id))
                .indexOf(Number(conf.ModelOptions.panelGlazing.id)) > -1
        );
    }

    private isValidInnerPanel(sash: ActiveSash, conf: DoorActiveConfiguration, doorSide: 'doorActive' | 'doorActiveInner') {
        let isBaseSideViable = true;
        if (conf.System?.door_type && doorSide === 'doorActive') {
            const panels = this.getMatchingInnerPanelsForSash(sash, sash, conf);
            if (!panels) return;

            const outerPanel = panels.find(p => Number(p.id) === Number(conf.Model?.id));
            const innerPanel = panels.find(p => Number(p.id) === Number(conf.ModelOptions?.panelInner?.id));

            if (outerPanel?.base_side === "any" && innerPanel?.base_side === "outside") {
                isBaseSideViable = false;
            }
            if (outerPanel?.base_side === "inside" && innerPanel?.base_side !== "inside") {
                isBaseSideViable = false;
            }
            if (outerPanel?.base_side !== "inside" && innerPanel?.base_side === "inside") {
                isBaseSideViable = false;
            }
            if (outerPanel?.base_side === "outside" && innerPanel?.base_side === "outside" && outerPanel?.id !== innerPanel?.id) {
                isBaseSideViable = false;
            }
        }

        return (
            isBaseSideViable && (
            ((!sash.glazing || sash.glazing.type !== 'door_panels') && !sash.panelInner)
            || (sash.glazing.type === 'door_panels'
                && sash.panelInner
                && this.getMatchingInnerPanelsForSash(sash, sash, conf)
                    .map(f => Number(f.id))
                    .indexOf(Number(sash.panelInner.id)) > -1))
        );
    }

    private isValidInnerPanelForModel(conf: DoorActiveConfiguration) {
        return (
            conf.ModelOptions.panelInner
            && this.getMatchingInnerPanelsForModel(conf)
                .map(f => Number(f.id))
                .indexOf(Number(conf.ModelOptions.panelInner.id)) > -1
        );
    }

    private getFillingFromId(id: string | null) {
        return this.allFillings.find(filling => filling.id === id) || null;
    }

    private isDoorPanel(filling: IccFilling) {
        return filling.type === 'deco_panels' || filling.type === 'door_panels';
    }

    isDoorPassiveSash(field: ActiveSash) {
        return field.type && (field.type.type === 'DRP' || field.type.type === 'DOP');
    }

    getUniqueOrderedFillings(fillings) {
        let distinctFillings = [];
        fillings.forEach(item => {
            if (!distinctFillings.includes(item.id)){
                distinctFillings.push(item.id);
            }
        });
        fillings = fillings.filter(filling=>{
            if (distinctFillings.includes(filling.id)){
                distinctFillings = distinctFillings.filter(el=>el!==filling.id);
                return true;
            } else {
                return false;
            }
        })
        return fillings;
    }

    getSashWidthInRebateBasedOnWidth(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const frameProfile = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const leftLightSize = (conf.doorSizes && conf.doorSizes.leftLightSize) || 0;
        const rightLightSize = (conf.doorSizes && conf.doorSizes.rightLightSize) || 0;

        return Number(conf.Width) - Number(leftLightSize) - Number(rightLightSize) - Number(2 * frameProfile?.spaceSash || 0) + (this.getBiggerRebateValueFromVirtualDoorSash(conf) * 2);
    }

    getSashHeightInRebateBasedOnHeight(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const frame = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const threshold = conf.UsedProfiles.find(p => p.type === 'threshold') || this.profilesService.getProfile(conf.ProfileSet.threshold);
        const topLight = (conf.doorSizes && conf.doorSizes.topLightSize) || 0;

        return Number(conf.Height) - Number(topLight) - Number(frame?.spaceSash) + Number(threshold?.spaceSash) + this.getBiggerRebateValueFromVirtualDoorSash(conf);
    }

    getBiggerRebateValueFromVirtualDoorSash(conf: DoorActiveConfiguration | WindowActiveConfiguration) {
        const virtualDoorSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');

        if (virtualDoorSash) {
            if (virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return Math.max(virtualDoorSash.rebateWidth, virtualDoorSash.rebateWidthOut)
            } else if (virtualDoorSash.rebateWidth && !virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidth;
            } else if (!virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidthOut
            } else {
                return 0;
            }
        }
    }

    getSashWidthBasedOnWidth(conf: DoorActiveConfiguration | WindowActiveConfiguration, withRebate = true) {
        const frameProfile = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const leftLightSize = (conf.doorSizes && conf.doorSizes.leftLightSize) || 0;
        const rightLightSize = (conf.doorSizes && conf.doorSizes.rightLightSize) || 0;

        return Number(conf.Width) - Number(leftLightSize) - Number(rightLightSize) - Number(2 * frameProfile?.spaceSash || 0) + (this.getRebateValueFromVirtualDoorSash(conf, withRebate) * 2);
    }

    getSashHeightBasedOnHeight(conf: DoorActiveConfiguration | WindowActiveConfiguration, withRebate = true) {
        const frame = conf.UsedProfiles.find(p => p.type === 'frame') || this.profilesService.getProfile(conf.ProfileSet.frameSide);
        const threshold = conf.UsedProfiles.find(p => p.type === 'threshold') || this.profilesService.getProfile(conf.ProfileSet.threshold);
        const topLight = (conf.doorSizes && conf.doorSizes.topLightSize) || 0;
        const rebate = conf.System.available_threshold_sash_rebate ? 1 : 2;

        return Number(conf.Height) - Number(topLight) - Number(frame?.spaceSash) - Number(threshold?.spaceSash || 0) + (this.getRebateValueFromVirtualDoorSash(conf, withRebate) * rebate);
    }

    getRebateValueFromVirtualDoorSash(conf: DoorActiveConfiguration | WindowActiveConfiguration, max = true) {
        const virtualDoorSash = conf.UsedProfiles.find(p => p.type === 'virtual_door_sash');
        if (virtualDoorSash) {
            if (virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return max
                    ? Math.max(virtualDoorSash.rebateWidth, virtualDoorSash.rebateWidthOut)
                    : Math.min(virtualDoorSash.rebateWidth, virtualDoorSash.rebateWidthOut)
            } else if (virtualDoorSash.rebateWidth && !virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidth;
            } else if (!virtualDoorSash.rebateWidth && virtualDoorSash.rebateWidthOut) {
                return virtualDoorSash.rebateWidthOut
            } else {
                return 0;
            }
        }
    }
}
